import { DocumentNode, gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { Controller, UseFormWatch } from 'react-hook-form';
import { FormValues, SearchType } from './HomeForm';
import { HiBriefcase } from 'react-icons/hi';
import { useRouter } from 'next/router';
import { Input } from 'reactstrap';
import { FiUser } from 'react-icons/fi';

const SPECIALIST_NAMES_QUERY = gql`
	query SpecsSuggestionQuery($_ilike: String = "", $profession_type: String!) {
		specialists(where: { shown_name: { _ilike: $_ilike } }, limit: 3) {
			shown_name
			avatar_url
			id
			is_active
			profession {
				name
			}
			secondary_professions_specialists {
				profession {
					name
				}
			}
		}
		professions(
			where: {
				name: { _ilike: $_ilike }
				parent_id: { _is_null: true }
				type: { _eq: $profession_type }
			}
			limit: 3
		) {
			name
			slug
			is_active
			profession {
				name
			}
		}
	}
`;

const PLACES_NAMES_QUERY = gql`
	query PlaceSuggestionsQuery($_ilike: String = "", $profession_type: String!) {
		places(where: { name: { _ilike: $_ilike } }, limit: 3) {
			name
			avatar_url
			id
			profession {
				name
			}
		}
		professions(
			where: {
				name: { _ilike: $_ilike }
				parent_id: { _is_null: true }
				type: { _eq: $profession_type }
			}
			limit: 3
		) {
			name
			slug
			is_active
			profession {
				name
			}
		}
	}
`;

const EVENTS_NAMES_QUERY = gql`
	query EventSuggestionsQuery($_ilike: String = "", $profession_type: String!) {
		specialists(where: { shown_name: { _ilike: $_ilike } }, limit: 3) {
			shown_name
			avatar_url
			id
			is_active
			profession {
				name
			}
			secondary_professions_specialists {
				profession {
					name
				}
			}
		}
		professions(
			where: {
				name: { _ilike: $_ilike }
				parent_id: { _is_null: true }
				type: { _eq: $profession_type }
			}
			limit: 3
		) {
			name
			slug
			is_active
			profession {
				name
			}
		}
	}
`;

type Props = {
	control: any;
	watch: UseFormWatch<FormValues>;
	placeholder?: string;
	home?: boolean;
	searchType?: SearchType;
};

const queries: { [searchType: string]: DocumentNode } = {
	['specialist']: SPECIALIST_NAMES_QUERY,
	['place']: PLACES_NAMES_QUERY,
	['event']: EVENTS_NAMES_QUERY
};

export const HomeFormSpecField: React.FC<Props> = ({
	control,
	watch,
	placeholder = 'specjalizacja lub nazwisko',
	home = false,
	searchType
}) => {
	const router = useRouter();

	const [suggestions, setSuggestions] = useState<any[]>([]);

	const [getSuggestionsFromApi, { data: suggestionsFromApi, loading, error }] =
		useLazyQuery(queries[searchType ?? 'specialist'], {
			ssr: true
		});

	const nameForSuggestions = watch('profession') || '';

	useEffect(() => {
		if (
			!!nameForSuggestions &&
			!suggestionsFromApi?.professions?.some(
				(p: any) => p.name === nameForSuggestions
			) &&
			!suggestionsFromApi?.professions?.some(
				(p: any) => p.slug === nameForSuggestions
			) &&
			!suggestionsFromApi?.specialists?.some(
				(p: any) => p.shown_name === nameForSuggestions
			)
		) {
			const debounced = setTimeout(
				() =>
					getSuggestionsFromApi({
						variables: {
							_ilike: `%${nameForSuggestions}%`,
							profession_type: searchType === 'place' ? 'place' : 'specialist'
						}
					}),
				500
			);
			return () => clearTimeout(debounced);
		}
	}, [nameForSuggestions]);

	const getSuggestions = () => {
		if (!suggestionsFromApi) return [];

		return (
			searchType === 'place'
				? suggestionsFromApi.places
				: suggestionsFromApi.specialists
		)
			.map((s: any) => ({
				...s,
				type: searchType === 'place' ? 'place' : 'specialist'
			}))
			.concat(
				suggestionsFromApi.professions.map((p: any) => ({
					...p,
					type: 'profession'
				}))
			);
	};

	const onSuggestionsFetchRequested = () => {
		setSuggestions(getSuggestions());
	};

	useEffect(() => {
		if (!!suggestionsFromApi) onSuggestionsFetchRequested();
	}, [suggestionsFromApi]);

	const getSuggestionValue = (suggestion: any) => {
		if (suggestion.type === 'specialist' && searchType === 'specialist') {
			router.push(`/specjalista/${suggestion.id}`);
		}

		if (suggestion.type === 'place' && searchType === 'place') {
			router.push(`/place/${suggestion.id}`);
		}
		return suggestion.name ?? suggestion.shown_name;
	};

	const onSuggestionsClearRequested = () => setSuggestions([]);

	const renderSuggestion = (suggestion: any) => (
		<div className='flex w-full bg-white p-2 cursor-pointer hover:bg-slate-200 border z-50'>
			<div className='w-12 mr-2 flex items-start justify-center'>
				{suggestion.type !== 'profession' && (
					<img src={suggestion.avatar_url} className='rounded-md' />
				)}
				{suggestion.type === 'profession' && (
					<HiBriefcase className='text-gray-500 mt-1' size={30} />
				)}
			</div>
			<div className='flex w-full justify-between'>
				<div className='flex flex-col justify-between'>
					<div className=' text-md'>
						{suggestion.type === 'specialist' && (
							<span>{suggestion.shown_name}</span>
						)}
						{suggestion.type === 'place' && <span>{suggestion.name}</span>}
						{suggestion.type === 'profession' && <span>{suggestion.name}</span>}
					</div>
					<div className=' text-xxs text-gray-600'>
						{suggestion.type === 'specialist' && (
							<span>
								{suggestion.profession.name}
								{suggestion.secondary_professions_specialists[0] && (
									<span>-</span>
								)}
								{
									suggestion.secondary_professions_specialists[0]?.profession
										?.name
								}
							</span>
						)}
						{suggestion.type === 'profession' && (
							<span>{suggestion.profession?.name}</span>
						)}
						{suggestion.type === 'place' && (
							<span>{suggestion.profession?.name}</span>
						)}
					</div>
				</div>
				<div className='text-xs'>
					{suggestion.type === 'specialist' && <span>Specjalista</span>}
					{suggestion.type === 'profession' && <span>Specjalizacja</span>}
					{suggestion.type === 'place' && <span>Placówka</span>}
				</div>
			</div>
		</div>
	);

	const showIcon = !home;

	return (
		<>
			{showIcon && (
				<FiUser size={18} className='m-3.5 absolute z-30 text-gray-400 ' />
			)}

			<Controller
				control={control as any}
				name='profession'
				render={({ field }) => (
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						getSuggestionValue={getSuggestionValue}
						containerProps={{
							className: `absolute w-full z-20`
						}}
						inputProps={{
							style: {
								padding: '0.6rem',
								paddingLeft: showIcon ? '2.4rem' : '0.6rem'
							},
							placeholder,
							className: `pr-14 rounded-lg w-full outline-none focus:outline-blue-600 focus:outline-1 outline-offset-0 border border-gray-400 focus:border-blue-600 shadow `,
							value: field.value,
							onChange: (event, { newValue }) => field.onChange(newValue)
						}}
						renderSuggestion={renderSuggestion}
					/>
				)}
			/>
		</>
	);
};
