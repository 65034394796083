import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatSimpleAddress } from 'common/formatters/simple-address';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { useSessionContext } from 'common/session/session-context';
import { useTranslate } from 'common/translate/translate';
import { yup } from 'common/yup';
import { ButtonWithSpinner } from 'components/button-with-spinner/button-with-spinner.component';
import {
	intervals,
	sortIntervals
} from 'components/calendar-settings/single-calendar-day-settings';
import { GET_RESERVATIONS } from 'components/calendars/pending-reservations';
import dayjs, { Dayjs } from 'dayjs';
import { AddressDto } from 'entities/address.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import specialist from 'pages/api/invite/specialist';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from 'reactstrap';
import { AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { FiChevronDown } from 'react-icons/fi';
import { selectStyle } from 'components/my-account/specialist/reservations-calendar';
import { DropdownWrapper } from 'components/dropdown/dropdown-wrapper';

type Props = {
	dates: string[];
	addressId: number;
	onCancel: () => void;
	specialist: SpecialistDto;
};

type FormValues = {
	name: string;
	capacity: number;
	timespan: number;
	price: string;
	description: string;
};

const validationSchema = yup.object({
	name: yup.string().required().label('"nazwa wydarzenia"'),
	capacity: yup
		.number()
		.positive('Liczba miejsc musi wynosić co najmniej 1')
		.typeError('Proszę podać liczbę')
		.required()
		.label('"ilość miejsc"'),
	timespan: yup.string().required().label('"czas trwania"'),
	price: yup
		.number()
		.typeError('Proszę podać liczbę')
		.required()
		.label('"cena"'),
	description: yup.string()
});

const ADD_EVENT = gql`
	mutation add_event(
		$address_id: Int!
		$capacity: Int!
		$description: String!
		$event_place: String!
		$name: String!
		$price: String!
		$timespan: float8!
		$dates: json!
	) {
		add_event(
			dates: $dates
			address_id: $address_id
			capacity: $capacity
			event_place: $event_place
			name: $name
			price: $price
			timespan: $timespan
			description: $description
		) {
			success
		}
	}
`;

export const AddEventModal: React.FC<Props> = ({
	dates,
	addressId,
	specialist,
	onCancel
}) => {
	const t = useTranslate();

	const session = useSessionContext();

	const {
		register,
		handleSubmit,
		watch,
		setError,
		control,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(validationSchema)
	});

	const fields = watch();

	const allAddresses = [
		...specialist.addressesses,
		...specialist.calendar_settings_by_specialist_id
			.map((item) => item.addressess)
			.flat()
	];

	const currentAddress = allAddresses.find((x) => x.id === addressId);

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const [
		addEvent,
		{ data: eventData, error: eventError, loading: eventLoading }
	] = useMutation(ADD_EVENT);

	const onSubmit = (values: FormValues) => {
		const variables = {
			...values,
			price: values.price.toString(),
			dates,
			address_id: currentAddress?.id,
			event_place: formatSimpleAddress(currentAddress as AddressDto)
		};

		addEvent({ variables });
	};

	useEffect(() => {
		if (!!eventData) {
			window.location.reload();
		}
	}, [eventData]);

	const sortDates = (a: string, b: string) =>
		dayjs(a).valueOf() - dayjs(b).valueOf();

	const getSmallestPossibleInterval = () => {
		const intervals = dates.map((date, i) => {
			const a = dayjs(date);
			const prevDate = dates[i - 1];
			const b = dayjs(prevDate);

			return i === 0 ? 99999 : a.diff(b, 'minutes') / 60;
		});

		return intervals.sort()[0];
	};

	const smallestPossibleInterval = getSmallestPossibleInterval();

	const formatDatesSmartly = (date: string, index: number, dates: string[]) => {
		const dateFormat = 'DD.MM.YYYY';
		const hourFormat = 'HH:mm';
		const fullFormat = `${dateFormat} ${hourFormat}`;

		const dateObject = dayjs(date);

		if (index === 0) {
			return dateObject.format(fullFormat);
		}

		const prevDateObject = dayjs(dates[index - 1]);

		if (
			prevDateObject.isSame(dateObject, 'day') &&
			prevDateObject.isSame(dateObject, 'month') &&
			prevDateObject.isSame(dateObject, 'year')
		) {
			return dateObject.format(hourFormat);
		}

		return dateObject.format(fullFormat);
	};

	const createDatesArray = () => {
		const dateFormat = 'DD.MM.YYYY';
		const hourFormat = 'HH:mm';

		let newDates: { [key: string]: string[] } = {};

		const sortedDates = dates.sort(sortDates);

		for (let i = 0; i < sortedDates.length; i++) {
			const date = dayjs(sortedDates[i]);
			const dateKey = date.format(dateFormat);
			const hour = date.format(hourFormat);

			newDates = {
				...newDates,
				[dateKey]: [...(newDates?.[dateKey] ?? []), hour]
			};
		}

		return Object.entries(newDates);
	};

	return (
		<Modal isOpen={true} size='lg' className='back-office mobile-fullscreen'>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<ModalHeader className='pl-4' toggle={() => onCancel()}>
					<div className='ml-4'>Dodaj wydarzenie</div>
				</ModalHeader>
				<ModalBody>
					<h3 className='text-bold text-xl p-3 bg-gray-100 rounded-lg mb-4 '>
						<div className='grid grid-cols-2 md:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-11 w-full'>
							{createDatesArray().map(([date, hours]) => (
								<div className='mx-2 mb-2'>
									<div className='text-base text-center mb-1'>{date}</div>
									<div className='text-xs text-center '>
										{hours.map((hour) => (
											<div className='rounded-lg border bg-white py-1'>
												{hour}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</h3>
					{currentAddress ? (
						<h3 className='text-bold text-xl p-4 bg-gray-100 rounded-lg mb-4'>
							Adres: {formatSimpleAddress(currentAddress)}
						</h3>
					) : null}

					<FormGroup>
						<Label>
							<strong>{t('Nazwa wydarzenia *')}</strong>
						</Label>
						<Input
							type='text'
							invalid={isInvalid('name')}
							{...customRegister('name')}
						/>
						<FormFeedback>{getError('name')}</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label>{t('Czas trwania *')}</Label>

						<Controller
							control={control}
							name='timespan'
							render={({ field: { value, onChange } }) => (
								<DropdownWrapper
									wrapperClassname='col-span-3 md:col-span-2 mb-3 md:mx-2 md:mt-3'
									toggleClassname='border py-3'
									toggleComponent={<span>{intervals[value]}</span>}
									options={sortIntervals().filter(
										([key, value]) =>
											parseFloat(key) <= smallestPossibleInterval
									)}
									onOptionClick={([timespan, label]) => onChange(timespan)}
									getOptionDisplay={([timespan, label]) => label}
								/>
							)}
						/>

						<FormFeedback>{getError('timespan')}</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label>{t('Cena *')}</Label>
						<Input
							type='text'
							invalid={isInvalid('price')}
							{...customRegister('price')}
						/>
						<FormFeedback>{getError('price')}</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label>{t('Ilość miejsc *')}</Label>
						<Input
							type='text'
							invalid={isInvalid('capacity')}
							{...customRegister('capacity')}
						/>
						<FormFeedback>{getError('capacity')}</FormFeedback>
					</FormGroup>
					<FormGroup>
						<Label>{t('Opis')}</Label>
						<Input
							type='textarea'
							invalid={isInvalid('description')}
							{...customRegister('description')}
						/>
						<FormFeedback>{getError('description')}</FormFeedback>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button onClick={() => onCancel()}>Wróć</Button>
					<ButtonWithSpinner
						className='ml-auto'
						isSpinning={eventLoading}
						color='primary'
						type='submit'
					>
						{t('Dodaj wydarzenie')}
					</ButtonWithSpinner>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
