import { gql, useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { UseFormWatch, Controller } from 'react-hook-form';
import { FormValues } from './HomeForm';
import { FiMapPin } from 'react-icons/fi';

const CITIES_QUERY = gql`
	query CitiesForSuggestionsQuery($cityName: String = "") {
		addressess_aggregate(
			distinct_on: city
			limit: 3
			where: { city: { _ilike: $cityName }, is_online: { _eq: false } }
		) {
			nodes {
				city
			}
		}
	}
`;

type Props = {
	control: any;
	watch: UseFormWatch<FormValues>;
	home?: boolean;
};

export const HomeFormCityField: React.FC<Props> = ({
	watch,
	control,
	home = false
}) => {
	const [suggestions, setSuggestions] = useState<any[]>([]);

	const [getSuggestionsFromApi, { data: suggestionsFromApi, loading, error }] =
		useLazyQuery(CITIES_QUERY, {
			ssr: true
		});

	const nameForSuggestions = watch('city');

	useEffect(() => {
		if (
			!!nameForSuggestions &&
			!suggestionsFromApi?.addressess_aggregate.nodes.some(
				(p: any) => p.name === nameForSuggestions
			)
		) {
			const debounced = setTimeout(
				() =>
					getSuggestionsFromApi({
						variables: { cityName: `%${nameForSuggestions}%` }
					}),
				500
			);
			return () => clearTimeout(debounced);
		}
	}, [nameForSuggestions]);

	const getSuggestions = () => {
		if (!suggestionsFromApi) return [];
		return suggestionsFromApi.addressess_aggregate.nodes;
	};

	const onSuggestionsFetchRequested = () => {
		setSuggestions(getSuggestions());
	};

	useEffect(() => {
		if (!!suggestionsFromApi) {
			onSuggestionsFetchRequested();
		}
	}, [suggestionsFromApi]);

	const getSuggestionValue = (suggestion: any) => {
		return suggestion.city;
	};

	const onSuggestionsClearRequested = () => setSuggestions([]);

	const renderSuggestion = (suggestion: any) => (
		<div className='flex w-full items-center bg-white py-2  cursor-pointer hover:bg-slate-200 border z-50'>
			<div className='w-8 flex items-start justify-center '>
				<FiMapPin className='text-gray-500 ' size={20} />
			</div>
			<div>{suggestion.city}</div>
		</div>
	);

	const showIcon = !home;

	return (
		<>
			{showIcon && (
				<FiMapPin className='text-gray-400  m-3.5 absolute z-20' size={18} />
			)}
			<Controller
				control={control as any}
				name='city'
				render={({ field }) => (
					<Autosuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={onSuggestionsFetchRequested}
						onSuggestionsClearRequested={onSuggestionsClearRequested}
						getSuggestionValue={getSuggestionValue}
						containerProps={{
							className: `absolute w-full z-10`
						}}
						inputProps={{
							placeholder: 'miejscowość',
							style: {
								padding: '0.6rem',
								paddingLeft: showIcon ? '2.4rem' : '0.6rem'
							},
							className: `pr-14 rounded-lg w-full outline-none focus:outline-blue-600 focus:outline-1 outline-offset-0 border border-gray-400 focus:border-blue-600 shadow `,
							value: field.value,
							onChange: (event, { newValue }) => field.onChange(newValue)
						}}
						renderSuggestion={renderSuggestion}
					/>
				)}
			/>
		</>
	);
};
