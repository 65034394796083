import { yup } from 'common/yup';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiHome, FiVideo, FiChevronDown, FiX, FiSearch } from 'react-icons/fi';
import Autosuggest from 'react-autosuggest';
import { gql, useLazyQuery } from '@apollo/client';
import { HomeFormSpecField } from './HomeFormSpecField';
import { HomeFormCityField } from './HomeFormCityField';
import { Button } from 'reactstrap';
import { useOnClickOutside } from 'common/use-on-click-outside';
import { SearchFilters } from 'common/query-params-filter-enum';
import { ProfessionDto } from 'entities/profession.dto';
import { EntityRadioGroup } from './entity-radio-group';

interface Props {
	data: any;
	vertical?: boolean;
	filters?: Partial<SearchFilters>;
	redirect?: boolean;
	specializationPlaceholder?: string;
	createSearchResultUrl: (
		specializationFilter: string,
		cityFilter?: string | undefined,
		onlineFilter?: boolean,
		redirect?: boolean,
		searchType?: SearchType
	) => string;
	home?: boolean;
	onChange?: (values: FormValues) => void;
	searchType?: SearchType;
	onSearchTypeChange?: (searchType: SearchType) => void;
	onOnlineChange?: (isOnline: boolean) => void;
}

const validationSchema = yup.object({});

export type SearchType = 'specialist' | 'event' | 'place';

export interface FormValues {
	online: string;
	profession: string;
	city: string;
	redirect?: boolean;
	searchType: SearchType;
}

export const HomeForm: React.FC<Props> = ({
	data,
	vertical = true,
	specializationPlaceholder,
	filters = {
		profession: '',
		city: ''
	},
	redirect = false,
	createSearchResultUrl = () => {
		return '/';
	},
	home = false,
	onChange = () => {},
	searchType,
	onSearchTypeChange,
	onOnlineChange
}) => {
	const {
		register,
		control,
		watch,
		setValue,
		getValues,
		handleSubmit,
		formState: { errors }
	} = useForm<FormValues>({
		defaultValues: {
			...filters,
			online: filters && filters.online ? 'tak' : 'nie',
			searchType: home ? 'specialist' : searchType
		}
	});
	const fields = watch();

	useEffect(() => {
		onChange(fields);
	}, [fields]);

	useEffect(() => {
		onOnlineChange?.(fields.online === 'tak');
	}, [fields.online]);

	const router = useRouter();

	const [active1, setActive1] = useState(false);
	const [active2, setActive2] = useState(false);

	useEffect(() => {
		if (fields.online === 'tak') {
			setValue('city', '');
		}
	}, [fields.online]);

	const onSubmit = ({ profession, city, online, searchType }: FormValues) => {
		router.push(
			createSearchResultUrl(
				profession,
				city,
				online === 'tak',
				redirect ? true : false,
				searchType
			)
		);
	};

	const ref = useRef<any>();
	useOnClickOutside(ref, () => {
		setActive1(false);
		setActive2(false);
	});

	const [currentPlaceholder, setCurrentPlaceholder] = useState<string>();

	useEffect(() => {
		if (fields.searchType === 'specialist' || fields.searchType === 'event') {
			setCurrentPlaceholder('specjalizacja lub nazwisko');
		}

		if (fields.searchType === 'place') {
			setCurrentPlaceholder('specjalizacja lub nazwa');
		}

		onSearchTypeChange?.(fields.searchType);
	}, [fields.searchType]);

	return (
		<form
			className={`flex gap-2 flex-col ${
				home ? '' : 'lg:flex-row lg:items-center'
			}`}
			onSubmit={handleSubmit(onSubmit)}
		>
			{home && <EntityRadioGroup name='searchType' register={register} />}
			{/*BUTTONS GROUP*/}
			<div
				className={`flex flex-grow flex-row md:flex-row gap-2 mr-1 items-center`}
			>
				<div className={`relative`}>
					<input
						className={`sr-only peer`}
						type='radio'
						value='nie'
						id='radio_1'
						{...register('online')}
					/>
					<label
						className={`flex w-38 justify-center font-medium items-center gap-x-2 px-4 py-2 border text-sm md:text-base
                rounded-3xl cursor-pointer hover:border-blue-600 
                peer-checked:text-blue-600 ${
									!home &&
									'peer-checked:border-blue-600 peer-checked:outline peer-checked:outline-1 border-gray-400'
								} peer-checked:bg-white 
                ${home ? ' text-white' : 'text-gray-600'}`}
						htmlFor='radio_1'
					>
						<FiHome />
						<span className={fields.online === 'nie' ? 'text-black' : ''}>
							Na miejscu
						</span>
					</label>
				</div>

				<div className='relative'>
					<input
						className={`sr-only peer`}
						type='radio'
						value='tak'
						id='radio_2'
						{...register('online')}
					/>
					<label
						className={`flex justify-center font-medium items-center gap-x-2 px-4 py-2 border text-sm md:text-base
                rounded-3xl cursor-pointer focus:outline-none hover:border-blue-600 
                peer-checked:text-blue-600  ${
									!home &&
									'peer-checked:border-blue-600 peer-checked:outline peer-checked:outline-1 border-gray-400'
								} peer-checked:bg-white
                 ${home ? ' text-white' : 'text-gray-600'}`}
						htmlFor='radio_2'
					>
						<FiVideo />
						<span className={fields.online === 'tak' ? 'text-black' : ''}>
							Online
						</span>
					</label>
				</div>
			</div>

			{/*SELECT*/}

			<div className={`select-none w-full flex flex-col md:flex-row gap-2`}>
				{active1 || active2 ? (
					<div
						onClick={() => {
							setActive2(false);
						}}
						className={`fixed top-0 left-0 w-full h-full bg-gray-900 opacity-50 z-40`}
					></div>
				) : null}
				<div className={`relative w-full ${active1 ? 'z-50' : null}`}>
					<HomeFormSpecField
						home={home}
						control={control}
						watch={watch}
						placeholder={currentPlaceholder}
						searchType={fields.searchType}
					/>
					{/* dropdownicon */}
					<div
						style={{ transform: 'scale(0.95)', transformOrigin: 'top' }}
						className={`absolute w-11 h-full p-3 pt-6 md:pt-3 m-px top-0 right-0 bg-white rounded-r-lg  flex items-center justify-center z-20 cursor-pointer`}
						onClick={() => setActive1(!active1)}
					>
						{active1 ? <FiX /> : <FiChevronDown />}
					</div>
					{active1 ? (
						<div
							ref={ref}
							style={{
								height: 'fit-content',
								maxHeight: '340px',
								width: '100%',
								zIndex: 999
							}}
							className={`overflow-y-auto customscroll w-full md:w-auto absolute text-left bg-white mt-1 rounded-lg flex flex-col right-0 cursor-pointer`}
						>
							{data?.professions &&
								data.professions
									.filter((p: ProfessionDto) => p.parent_id === null)
									.filter((p: ProfessionDto) => {
										if (Boolean(fields.searchType)) {
											if (
												fields.searchType === 'specialist' ||
												fields.searchType === 'event'
											) {
												return p.type === 'specialist';
											} else {
												return p.type === 'place';
											}
										}

										return true;
									})
									.map((el: any) => {
										return (
											<div
												key={el.slug}
												onClick={() => {
													setActive1(!active1);
													setValue('profession', el.name);
												}}
												className={`px-3 py-1 leading-8 border-b hover:bg-gray-100`}
											>
												{el.name}
											</div>
										);
									})}
						</div>
					) : null}
				</div>

				{fields.online === 'nie' && (
					<div
						className={`select-none relative w-full mt-10 md:mt-0 ${
							active2 ? 'z-50' : null
						}`}
					>
						<HomeFormCityField control={control} watch={watch} home={home} />
						{/* dropdownicon */}
						<div
							style={{ transform: 'scale(0.95)', transformOrigin: 'top' }}
							className={`absolute w-11 h-full p-3 pt-6 md:pt-3 top-0 right-0  rounded-r-lg  flex items-center justify-center z-10 cursor-pointer`}
							onClick={() => setActive2(!active2)}
						>
							{active2 ? <FiX /> : <FiChevronDown />}
						</div>
						{/* options*/}
						{active2 ? (
							<div
								ref={ref}
								style={{
									height: 'fit-content',
									maxHeight: '340px',
									width: '100%'
								}}
								className={`overflow-y-auto md:w-auto absolute text-left bg-white mt-1 rounded-lg  right-0 cursor-pointer z-30 customscroll`}
							>
								{data?.addressess_aggregate
									? data?.addressess_aggregate.nodes.map((el: any) => {
											return (
												<div
													key={el.slug}
													onClick={() => {
														setActive2(!active2);

														setValue('city', el.city);
													}}
													className={`w-full flex px-3 py-1 leading-8 border-b hover:bg-gray-100`}
												>
													{el.city}
												</div>
											);
									  })
									: []}
							</div>
						) : null}
					</div>
				)}
				<Button
					type='submit'
					color='primary'
					size='md'
					style={{ padding: '9px 18px' }}
					className='flex justify-center mt-10 md:mt-0'
				>
					<FiSearch size={18} className='mr-1 text-xl mt-0.5' /> Szukaj
				</Button>
			</div>
		</form>
	);
};
