import {ReactNode} from 'react';

type Props = {
    children: ReactNode,
    className: string
};

export const TopBar: React.FC<Props> = ({children, className}) => {
    return <div className={className}>{children}</div>;
};
