import { gql } from "@apollo/client";

export const GET_MY_USERS_QUERY = gql`
  query get_users($id: Int!) {
    users(where: { id: { _eq: $id } }) {
      specialist_id
      role
      profession_id
      phone
      last_name
      id
      first_name
      email
      specialist {
        id
        shown_name
        profession_id
        description
        city_id
        avatar_url
        experience
        secondary_professions_specialists {
          profession_id
        }
      }
    }
  }
`;

export const GET_MY_PLACES_USERS_QUERY = gql`
  query get_users($id: Int!) {
    users(where: { id: { _eq: $id } }) {
      specialist_id
      role
      profession_id
      phone
      last_name
      id
      first_name
      email
      specialist {
        id
        shown_name
        profession_id
        description
        city_id
        avatar_url
        experience
        secondary_professions_specialists {
          profession_id
        }
      }
      place {
        id
        name
        description
        avatar_url
        profession {
          id
          name
        }
        professions_places {
          id
          place_id
          profession_id
          profession {
            id
            name
          }
        }
      }
    }
  }
`;
