import classNames from 'classnames';
import { OpinionDto } from 'entities/opinion.dto';
import { useState } from 'react';
import { FaStar } from 'react-icons/fa';
import { FiStar } from 'react-icons/fi';

type Props = {
	stars: number;
	opinions?: number;
	onClick?: () => void;
	showOpinions?: boolean;
	onStarClick?: (starIndex: number) => void;
};

export const Stars: React.FC<Props> = ({
	stars = 0,
	opinions,
	children,
	onClick,
	showOpinions = true,
	onStarClick
}) => {
	const fill = (i: number) => (i < (stars || 0) ? '#facc15' : '#a1a1aa');

	return (
		<button
			type='button'
			className={classNames(`flex gap-x-0.5`, {
				'cursor-default': !onClick && !onStarClick
			})}
			onClick={() => {
				if (onClick) {
					onClick();
				}
			}}
		>
			{[...Array(5)].map((x, i) => (
				<FaStar
					key={i}
					fill={fill(i)}
					color={i < (Math.round(stars) || 0) ? 'facc15' : 'a1a1aa'}
					onClick={() => {
						if (onStarClick) onStarClick(i + 1);
					}}
				/>
			))}
			{!!opinions && opinions > 0 && showOpinions && (
				<p className={`text-xs text-gray-400 ml-1`}>
					{opinions}{' '}
					{opinions == 1 ? 'opinia' : opinions < 5 ? 'opinie' : 'opinii'}
				</p>
			)}
		</button>
	);
};
