import { gql, useMutation } from '@apollo/client';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { useSessionContext } from 'common/session/session-context';
import { useTranslate } from 'common/translate/translate';
import { yup, yupResolver } from 'common/yup';
import { ButtonWithSpinner } from 'components/button-with-spinner/button-with-spinner.component';
import { toastActions, ToastContext } from 'components/toasts/toasts-context';
import { UserDto } from 'entities/user.dto';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from 'reactstrap';

const HELP_REQUEST_OPERATION = gql`
	mutation help_request_mutation(
		$content: String = ""
		$context: json = {}
		$email: String = ""
		$user_id: Int!
		$user_first_name: String = ""
	) {
		help_request(
			input: {
				content: $content
				context: $context
				email: $email
				user_id: $user_id
				user_first_name: $user_first_name
			}
		) {
			success
		}
	}
`;

type Props = {
	user?: UserDto;
	context?: any;
	onClose: () => void;
};

type FormValues = {
	content: string;
	email?: string;
	signedIn: boolean;
	acceptPolicy: boolean;
};

const validationSchema = yup.object({
	content: yup.string().required().min(30).label('"treść"'),
	email: yup.string().when('signedIn', {
		is: false,
		then: yup.string().email().required().label('"adres email"')
	}),
	acceptPolicy: yup.string().when('signedIn', {
		is: false,
		then: yup
			.string()
			.equals(
				['true'],
				'Musisz wyrazić zgodę na kontakt ze strony administracji w ramach odpowiadania na Twoje zgłoszenie.'
			)
	})
});

export const AdminFormModal: React.FC<Props> = ({ onClose, user, context }) => {
	const userSession = useSessionContext();

	const [requestHelp, { data, loading, error }] = useMutation(
		HELP_REQUEST_OPERATION
	);

	const toastContext = useContext(ToastContext);

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch
	} = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			signedIn: Boolean(userSession.session)
		}
	});

	const fields = watch();

	const t = useTranslate();

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const onSubmit = (values: FormValues) => {
		if (values.signedIn) {
			requestHelp({
				variables: {
					user_id: userSession.session?.id,
					user_first_name: userSession.session?.first_name,
					email: userSession.session?.email,
					context,
					content: values.content
				}
			});
		} else {
			requestHelp({
				variables: {
					email: values.email,
					context,
					content: values.content
				}
			});
		}
	};

	useEffect(() => {
		if (data && !error) {
			toastContext.dispatch(
				toastActions.show({
					type: 'success',
					message: 'Twoja wiadomość została wysłana do administracji',
					duration: 8000
				})
			);

			onClose();
		} else if (!loading && error) {
			toastContext.dispatch(toastActions.showUnexpectedError());
		}
	}, [data, error, loading]);

	return (
		<Modal isOpen={true} className='back-office mobile-fullscreen'>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<ModalHeader toggle={onClose}>
					<div className='ml-6'>Wyślij wiadomość</div>
				</ModalHeader>
				<ModalBody>
					<FormGroup>
						<Label>Wiadomość do administracji</Label>
						<Input
							type='textarea'
							invalid={isInvalid('content')}
							{...customRegister('content')}
						/>
						<FormFeedback error>{getError('content')}</FormFeedback>
					</FormGroup>
					{fields.email && (
						<>
							<FormGroup>
								<Input
									type='email'
									invalid={isInvalid('content')}
									{...customRegister('content')}
								/>
								<FormFeedback error>{getError('content')}</FormFeedback>
							</FormGroup>
							<FormGroup check inline className='pt-3'>
								<Input
									type='checkbox'
									{...customRegister('acceptPolicy')}
									className='cursor-pointer'
									id='acceptPolicy'
									invalid={isInvalid('acceptPolicy')}
								/>
								<Label check htmlFor='acceptPolicy' className='cursor-pointer'>
									{t(
										'Akceptuję politykę prywatności Znany.pl i zgadzam się na kontakt korespondencyjny w ramach odpowiedzi na moją wiadomość. *'
									)}
								</Label>
								<FormFeedback className='d-block'>
									{getError('acceptPolicy')}
								</FormFeedback>
							</FormGroup>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<ButtonWithSpinner
						type='submit'
						color='primary'
						isSpinning={loading}
						className='ml-auto'
					>
						Wyślij
					</ButtonWithSpinner>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
