import classNames from 'classnames';
import { useBreakpoint } from 'common/use-breakpoint';
import { PlacePanelCard } from 'components/my-account/place-panel-card/place-panel-card';
import { SpecialistPanelCard } from 'components/specialist-panel-card/specialist-panel-card';
import { UserDto } from 'entities/user.dto';
import React from 'react';
import { UserPanelCard } from './user-panel-card';

type Props = {
	user: UserDto;
	isUnread?: boolean;
	isActive: boolean;
	onClick: () => void;
};

export const PersonOnList: React.FC<Props> = ({
	user,
	isActive,
	onClick,
	isUnread = false
}) => {
	const { breakpoint } = useBreakpoint();

	const isMobile = breakpoint === 'sm';

	return (
		<a
			className={classNames('block p-3 rounded-lg cursor-pointer', {
				'bg-green-100': isUnread,
				'bg-blue-100': isActive
			})}
			onClick={onClick}
		>
			<div className={classNames('flex', {})}>
				{user.specialist && (
					<SpecialistPanelCard
						justAvatar={isMobile}
						withLink={false}
						specialist={user.specialist}
						showStars={false}
					/>
				)}
				{user.place && (
					<PlacePanelCard
						avatarWidth={40}
						justAvatar={isMobile}
						withLink={false}
						place={user.place}
					/>
				)}
				{!user.place && !user.specialist && (
					<UserPanelCard justAvatar={isMobile} user={user} />
				)}
			</div>
		</a>
	);
};
