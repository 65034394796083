import { AddressDto } from "entities/address.dto";

export const sortAddresses =
	(cityFilter?: string, onlineFilter?: boolean) =>
	(a: AddressDto, b: AddressDto) => {
		if (onlineFilter) {
			if (a.is_online) {
				return -1;
			} else if (b.is_online) {
				return 1;
			}

			return 0;
		}

		if (cityFilter) {
			if (a.is_online) {
				return 1;
			}

			if (b.is_online) {
				return -1;
			}

			if (a.city && a.city.startsWith(cityFilter)) {
				return -1;
			} else if (b.city && b.city.startsWith(cityFilter)) {
				return 1;
			}
		}

		return 0;
	};