import { Avatar, Booking, Addresses } from 'components/front';
import { FiHome, FiCheck, FiVideo } from 'react-icons/fi';
import React, { useState } from 'react';
import { AddressDto } from 'entities/address.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import TextTruncate from 'react-text-truncate';
import { formatHours } from 'common/formatters/format-hours';
import { sortAddresses } from 'common/addresses/sort-addresses';
import { Price } from './price.component';
import { ServiceDto } from 'entities/service.dto';
import { hasPremiumBasedOnUserSession } from 'common/premium/has-premium-based-on-expiration-date';
import { useSessionContext } from 'common/session/session-context';

interface Props {
	specialist: SpecialistDto;
	cityFilter?: string;
	onlineFilter: boolean;
}

export const SpecialistCard: React.FC<Props> = ({
	specialist,
	cityFilter,
	onlineFilter
}) => {
	const [activeAddress, setActiveAddress] = useState(0);

	if (!specialist || !specialist.addressesses) {
		return null;
	}

	const addressesToShow = [
		...specialist.addressesses,
		...specialist.calendar_settings_by_specialist_id.map(
			(item) => item.addressess
		)
	].sort(sortAddresses(cityFilter, onlineFilter));

	const [isTruncated, setIsTruncated] = useState<boolean>(true);

	const userSession = useSessionContext();

	const hasPremium = hasPremiumBasedOnUserSession(userSession);

	return (
		<div
			className={`grid grid-cols-2 bg-white gap-2
       mb-4 border border-gray-100 rounded-xl p-4`}
		>
			<div
				className={`grid grid-cols-5 gap-2  content-start col-span-3 lg:col-span-1`}
			>
				<Avatar
					paymentExpirationDate={specialist.user.payment_expiration_at}
					id={specialist.id}
					colSpan={4}
					shown_name={specialist.shown_name}
					profession={specialist.profession}
					avatar_url={specialist.avatar_url}
					stars={specialist.opinions_aggregate.aggregate.avg.rating}
					opinions={specialist.opinions_aggregate.aggregate.count}
					secondaryProfessions={specialist.secondary_professions_specialists.map(
						(p: any) => p.profession
					)}
				/>
				<div
					className={`text-sm col-span-5 text-gray-500 mt-4 whitespace-pre-wrap`}
				>
					<p>
						{isTruncated && (
							<TextTruncate
								line={3}
								element='span'
								truncateText='…'
								text={specialist.description}
								textTruncateChild={
									<a
										className='cursor-pointer text-blue-700'
										onClick={() => setIsTruncated(false)}
									>
										Rozwiń
									</a>
								}
							/>
						)}
						{!isTruncated && (
							<span>
								{specialist.description}
								<div className='mt-4'>
									<a
										className='cursor-pointer text-blue-700'
										onClick={(event) => {
											event.preventDefault();
											setIsTruncated(true);
										}}
									>
										Zwiń
									</a>
								</div>
							</span>
						)}
					</p>

					<div className={`flex flex-wrap border-b text-sm mb-3 gap-x-5 mt-4`}>
						{addressesToShow
							.sort((a, b) => (a.is_online ? 1 : -1))
							.map((address: AddressDto, i: number) => (
								<div
									key={`${i}`}
									className={`font-bold flex items-center justify-center gap-x-1 py-2.5 select-none  
                  ${
										activeAddress === i
											? 'border-b-2 border-blue-600 cursor-auto text-blue-600'
											: 'cursor-pointer'
									}`}
									onClick={() => setActiveAddress(i)}
								>
									<div className={`text-xs`}>
										{address.is_online ? <FiVideo /> : <FiHome />}
									</div>
									{address.is_online ? 'Online' : `Adres ${i + 1}`}
								</div>
							))}
					</div>
					{addressesToShow.length > 0 ? (
						<div>
							<Addresses address={addressesToShow[activeAddress]} />
							{specialist?.services
								.filter(
									(service) =>
										service.addresses &&
										(service.addresses as string[]).includes(
											addressesToShow[activeAddress].id.toString()
										)
								)
								.map((el: ServiceDto, i: number) => {
									return (
										<div
											className='grid grid-cols-7 py-1 mb-2 md:mb-0 lg:mb-2 xl:mb-0'
											key={`${i}`}
										>
											<div className='flex items-start col-span-4'>
												<FiCheck size={16} className='mr-1 ' />
												{el.name}
											</div>
											<div className='col-span-3 flex flex-col justify-between  ml-5'>
												<div className='text-right'>
													<Price
														price={el.price}
														priceTo={el.price_to}
														priceNegotiable={el.price_negotiable}
														hasDiscount={hasPremium}
													/>
												</div>
												<div className='text-right text-xs text-gray-400'>
													{formatHours(el.timespan)}
												</div>
											</div>
										</div>
									);
								})}
						</div>
					) : (
						<div className='mt-2 bg-yellow-100 p-2'>
							Specjalista nie udostępia adresu
						</div>
					)}
				</div>
			</div>

			<div className={` pl-4 lg:border-l col-span-2 lg:col-span-1`}>
				{specialist && addressesToShow[activeAddress] && (
					<Booking
						address={addressesToShow[activeAddress]}
						reservations={specialist.reservations}
						specialist={specialist}
						calendar_settings_by_specialist_id={
							specialist.calendar_settings_by_specialist_id
						}
					/>
				)}
			</div>
		</div>
	);
};
