import { uploadImage } from 'api-common/images';
import { CropModal } from 'components/crop-modal/crop-modal';
import { toastActions, ToastContext } from 'components/toasts/toasts-context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Label } from 'reactstrap';

type Props = {
	avatarUrl: string;
	onChange: (newAvatarUrl: string) => void;
};

function readFile(file: Blob) {
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.addEventListener('load', () => resolve(reader.result), false);
		reader.readAsDataURL(file);
	});
}

export const AvatarUpload: React.FC<Props> = ({ avatarUrl, onChange }) => {
	const [src, setSrc] = useState<string>();

	const toastContext = useContext(ToastContext);

	const [toCrop, setToCrop] = useState<string | null>(null);

	useEffect(() => {
		setSrc(avatarUrl);
	}, [avatarUrl]);

	useEffect(() => {});

	const onFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files.length > 0) {
			const file = event.target.files[0];
			let imageDataUrl = await readFile(file);

			setToCrop(imageDataUrl as string);
		}
	};

	const onSave = async (base64: string) => {
		const result = await uploadImage('avatar', base64);

		if (!result || result.status !== 201) {
			toastContext.dispatch(toastActions.showUnexpectedError());

			throw 'Error';
		}

		const resultBody = await result.json();
		setSrc(resultBody.url);

		setToCrop(null);

		onChange(resultBody.url);
	};

	return (
		<div className='flex justify-center relative flex-col'>
			{Boolean(src) && (
				<img src={src} className='avatar-preview rounded-md border-2' />
			)}
			<div className='flex-1'>
				<Button
					tag='label'
					htmlFor='user_avatar'
					color='primary'
					className='inline-block px-5 mt-1'
				>
					Zmień zdjęcie profilowe
				</Button>
				<input
					aria-describedby='user_avatar_help'
					id='user_avatar'
					type='file'
					className='w-1 h-1 bg-transparent inline-block ring-0 ring-transparent text-transparent border-0 -z-50 relative'
					onChange={onFileChange}
				/>
			</div>
			{!!toCrop && (
				<CropModal
					src={toCrop}
					onCancel={() => setToCrop(null)}
					onSave={onSave}
				/>
			)}
		</div>
	);
};
