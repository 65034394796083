import userBrkpt from 'use-breakpoint';

/**
 * It is important to bind the object of breakpoints to a variable for memoization to work correctly.
 * If they are created dynamically, try using the `useMemo` hook.
 */
const BREAKPOINTS = { sm: 0, md: 768, lg: 1024, xl: 1280, '2xl': 1536 };

export const useBreakpoint = () => {
	return userBrkpt(BREAKPOINTS, 'lg', false);
};
