import { gql, useMutation } from '@apollo/client';
import { DaySettings, generateEvents } from 'common/booking/generate-events';
import { formatWeekDay } from 'common/formatters/format-calendar-date';
import { hasPremiumBasedOnUser } from 'common/premium/has-premium-based-on-expiration-date';
import { useSessionContext } from 'common/session/session-context';
import { AddBreakModal } from 'components/add-break/add-break-modal';
import { AddEventModal } from 'components/add-event/add-event-modal';
import { SendMessageButton } from 'components/chat/send-message-button';
import { JoinEventModal } from 'components/join-event-modal/join-event-modal';
import { ReserveModal } from 'components/reserve/reserve-modal';
import dayjs, { Dayjs } from 'dayjs';
import { AddressDto } from 'entities/address.dto';
import { CalendarSettingsDto } from 'entities/calendar-settings.dto';
import { EventDto } from 'entities/event.dto';
import { ReservationDto } from 'entities/reservation.dto';
import { ServiceDto } from 'entities/service.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import { range } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Button, Modal, ModalHeader } from 'reactstrap';

const weekdays = [
	'monday',
	'tuesday',
	'wendesday', // dont correct this typo, you'll destroy database data
	'thursday',
	'friday',
	'saturday',
	'sunday'
];

type Props = {
	address: AddressDto;
	service?: ServiceDto;
	reservations: ReservationDto[];
	specialist: SpecialistDto;
	isBySpecialist?: boolean;
	isAddBreak?: boolean;
	isAddEvent?: boolean;
	calendar_settings_by_specialist_id: CalendarSettingsDto[];
	onTimeChoose?: () => void;
	hideHeader?: boolean;
	isByPlace?: boolean;
	isEmbed?: boolean;
	onEmbedFinish?: () => void;
};

const GET_NEXT_FREE_TERM = gql`
	mutation get_next_free_term(
		$address_id: Int!
		$calendar_settings: String!
		$specialist_id: Int!
		$get_after: timestamptz!
	) {
		get_next_free_term(
			address_id: $address_id
			calendar_settings: $calendar_settings
			specialist_id: $specialist_id
			get_after: $get_after
		) {
			next_free_term
		}
	}
`;

export const Booking: React.FC<Props> = ({
	address,
	service,
	reservations = [],
	specialist,
	isBySpecialist = false,
	isByPlace = false,
	isAddBreak = false,
	isAddEvent = false,
	calendar_settings_by_specialist_id,
	onTimeChoose,
	hideHeader = false,
	isEmbed = false,
	onEmbedFinish
}) => {
	if (!address) {
		return null;
	}
	const userSession = useSessionContext();

	const [startDate, setStartDate] = useState(dayjs().startOf('day'));
	const [endDate, setEndDate] = useState(dayjs().add(4, 'days'));
	const [nextFreeTerm, setNextFreeTerm] = useState<Dayjs>();

	const days = range(endDate.diff(startDate, 'day'));

	const [reserving, setReserving] = useState<any>();
	const [eventReserving, setEventReserving] = useState<EventDto>();

	const [selectedDates, setSelectedDates] = useState<string[]>([]);

	const isDateSelected = (datestring: string) =>
		selectedDates.includes(datestring);

	const handleDateSelect = (date: Dayjs) => {
		const datestring = date.toISOString();
		const isSelected = selectedDates.includes(datestring);
		isSelected
			? setSelectedDates(selectedDates.filter((d) => d !== datestring))
			: setSelectedDates([...selectedDates, datestring]);
	};

	useEffect(() => {
		setSelectedDates([]);
	}, [address]);

	const [
		getNextFreeTerm,
		{ data: nextData, loading: nextLoading, error: nextError }
	] = useMutation(GET_NEXT_FREE_TERM);

	const allEvents: any[] = [];

	const shouldGetNextFreeTerm = () =>
		allEvents.every((e) => Boolean(e.isCovered));

	useEffect(() => {
		if (shouldGetNextFreeTerm()) {
			const calendarSettingsSource =
				address && address.place_id
					? calendar_settings_by_specialist_id.find(
							(calendarSetting) => calendarSetting.addressess.id === address.id
					  )?.calendar_settings
					: address.calendar_settings;

			getNextFreeTerm({
				variables: {
					address_id: address.id,
					calendar_settings: calendarSettingsSource,
					specialist_id: specialist.id,
					get_after: endDate.endOf('day')
				}
			});
		}
	}, [startDate, address]);

	useEffect(() => {
		if (!!nextData) {
			const response = nextData.get_next_free_term.next_free_term;
			setNextFreeTerm(response ? dayjs(response) : undefined);
		}
	}, [nextData]);

	const calendarSettingsSource =
		address && address.place_id
			? calendar_settings_by_specialist_id.find(
					(calendarSetting) => calendarSetting.addressess.id === address.id
			  )?.calendar_settings
			: address.calendar_settings;

	const calendarSettings: { [key: string]: DaySettings } =
		calendarSettingsSource ? JSON.parse(calendarSettingsSource) : null;

	if (!calendarSettings) {
		return null;
	}

	if (!days) {
		return null;
	}

	const hasSpecialistPremium = hasPremiumBasedOnUser(specialist.user);

	return (
		<div>
			<div>
				{!(allEvents.length === 0 && !nextFreeTerm) && hasSpecialistPremium && (
					<>
						{!hideHeader && (
							<p className='text-lg text-center font-medium pt-1'>
								Wolne terminy
							</p>
						)}
						<div className='text-xs grid grid-cols-12 text-gray-600'>
							<div className='pt-3 pr-2 float-left'>
								{!startDate.isSame(dayjs(), 'day') ? (
									<a
										href='#'
										onClick={(event: React.MouseEvent) => {
											event.preventDefault();

											if (startDate.subtract(1, 'day').isSame(dayjs(), 'day')) {
												return;
											}

											setStartDate(startDate.subtract(4, 'day'));
											setEndDate(endDate.subtract(4, 'day'));
										}}
									>
										<FiArrowLeft fontSize={20} />
									</a>
								) : (
									<FiArrowLeft className='text-gray-300' fontSize={20} />
								)}
							</div>

							<div className='col-span-10 grid grid-cols-4 max-h-96 md:max-h-80 overflow-y-auto customscroll pr-2 mr-2'>
								{days.map((v) => {
									const thisDate = startDate.add(v, 'day');

									let validWeekday = thisDate.day() - 1;

									if (validWeekday < 0) {
										validWeekday = 6;
									}

									return (
										<div
											className='py-2 font-normal text-center sticky top-0 bg-white'
											key={v}
										>
											<div className='text-sm font-bold text-black'>
												{formatWeekDay(thisDate)}
											</div>
											<div>{thisDate.format('D MMM')}</div>
										</div>
									);
								})}

								{days.map((dayNumber: number) => {
									const thisDate = startDate.add(dayNumber, 'd');

									let validWeekday = thisDate.day() - 1;

									if (validWeekday < 0) {
										validWeekday = 6;
									}

									const dayOfWeek = weekdays[validWeekday];

									const daySettings = calendarSettings[dayOfWeek];

									if (!daySettings || !daySettings.enabled) {
										return (
											<div key={dayNumber} className='p-px'>
												&nbsp;
											</div>
										);
									}

									const events = generateEvents(
										thisDate,
										daySettings,
										reservations,
										address.id,
										userSession.session?.id,
										isBySpecialist
									);

									allEvents.push(...events);

									return (
										<div key={dayNumber} className='p-px'>
											{events.map((item: any) => {
												if (
													item.isCovered ||
													(item.isEvent && (isAddEvent || isAddBreak))
												) {
													return (
														<div
															key={item.display}
															className='line-through text-center font-medium w-full py-1 md:px-2 mb-1 rounded bg-orange-50 border border-orange-50 text-orange-500 cursor-pointer hover:border-orange-500'
														>
															{item.display}
														</div>
													);
												}

												if (item.isEvent) {
													return (
														<button
															type='button'
															onClick={() => {
																setEventReserving(item.event);
																onTimeChoose?.();
															}}
															key={item.display}
															className='text-center font-medium w-full py-1 md:px-2 mb-1 rounded bg-purple-200 border border-purple-200 text-purple-700 cursor-pointer hover:border-purple-700'
														>
															{item.display}
														</button>
													);
												}

												const isSelected = isDateSelected(
													item.date.toISOString()
												);

												return (
													<button
														type='button'
														key={item.display}
														onClick={() => {
															if (!isAddEvent) {
																setReserving(item);
															} else {
																handleDateSelect(item.date);
															}
															onTimeChoose?.();
														}}
														className={`font-medium w-full py-1 md:px-2 mb-1 rounded border border-blue-50 cursor-pointer ${
															isSelected
																? 'bg-green-100 border-green-100 hover:border-green-600 text-green-600'
																: 'bg-blue-50 border-blue-50 hover:border-blue-600 text-blue-600'
														}`}
													>
														{item.display}
													</button>
												);
											})}
										</div>
									);
								})}

								{eventReserving && (
									<JoinEventModal
										onCancel={() => {
											setEventReserving(undefined);
										}}
										isBySpecialist={isBySpecialist}
										event={eventReserving}
									/>
								)}

								{!!reserving &&
									(isAddBreak ? (
										<AddBreakModal
											addressId={address.id}
											specialist={specialist}
											date={reserving.date}
											onCancel={() => setReserving(null)}
										/>
									) : isAddEvent ? (
										<AddEventModal
											addressId={address.id}
											dates={selectedDates}
											specialist={specialist}
											onCancel={() => setReserving(null)}
										/>
									) : (
										<ReserveModal
											onEmbedFinish={onEmbedFinish}
											isEmbed={isEmbed}
											serviceId={service?.id}
											addressId={address.id}
											date={reserving.date}
											specialist={specialist}
											onCancel={() => setReserving(null)}
											isBySpecialist={isBySpecialist}
											isByPlace={isByPlace}
											dayEndHour={
												calendarSettings[
													weekdays[
														dayjs(reserving.date).day() - 1 < 0
															? 6
															: dayjs(reserving.date).day() - 1
													]
												].end
											}
										/>
									))}
							</div>
							<div className='pt-3 pr-2 float-right hover:text-blue-600'>
								<a
									href='#'
									onClick={(event: React.MouseEvent) => {
										event.preventDefault();

										setStartDate(startDate.add(4, 'day'));
										setEndDate(endDate.add(4, 'day'));
									}}
								>
									<FiArrowRight fontSize={20} />
								</a>
							</div>
						</div>
					</>
				)}

				{!(allEvents.length === 0 && !nextFreeTerm) &&
					isAddEvent &&
					hasSpecialistPremium && (
						<div className='flex'>
							<Button
								disabled={selectedDates.length === 0}
								onClick={() => setReserving(true)}
								color='primary'
								className='ml-auto mt-16'
							>
								Dalej
							</Button>
						</div>
					)}

				{shouldGetNextFreeTerm() && !!nextFreeTerm && hasSpecialistPremium && (
					<div className='grid grid-cols-12'>
						<div className='col-span-11 bg-slate-100 p-3 rounded-lg mt-4 flex flex-col items-center'>
							<div className='mb-4 md:mb-2 text-center text-sm md:text-base'>
								Kolejny wolny termin: {nextFreeTerm?.format('DD MMM, HH:mm')}
							</div>
							<Button
								onClick={() => {
									setStartDate(nextFreeTerm);
									setEndDate(nextFreeTerm.add(4, 'day'));
								}}
								color='primary'
								className='flex items-center justify-center w-full md:w-fit text-sm '
							>
								Pokaż wolne terminy <FiArrowRight className='ml-2' />{' '}
							</Button>
						</div>
					</div>
				)}

				{allEvents.length === 0 &&
					isBySpecialist &&
					!nextFreeTerm &&
					hasSpecialistPremium && (
						<>
							<div className='w-full block py-3 lg:py-20 text-center text-md'>
								<div className='pb-5'>Brak terminów</div>
							</div>
						</>
					)}
				{((allEvents.length === 0 && !isBySpecialist && !nextFreeTerm) ||
					!hasSpecialistPremium) && (
					<div className='w-full block py-3 lg:py-20 text-center text-md'>
						<div className='mb-5'>Skontaktuj się w celu umówienia wizyty</div>
						<SendMessageButton
							className='mx-auto'
							outline={false}
							user={{
								...specialist.user,
								specialist
							}}
						/>
					</div>
				)}
			</div>
		</div>
	);
};
