import { AddressDto } from 'entities/address.dto';

export const formatSimpleAddress = (address: AddressDto) => {
	if (!address) return '';

	if (address.is_online) {
		return `Online`;
	}
	return `${address.street} ${address.building_no ? address.building_no : ''}${
		address.local_no ? ` lok. ${address.local_no}` : ''
	}, ${address.postal_code || ''} ${address.city}`;
};
