import { useQuery } from '@apollo/client';
import classNames from 'classnames';

import { DropdownWrapper } from 'components/dropdown/dropdown-wrapper';
import { GET_MAIN_PROFESSIONS_QUERY } from 'gql/professions';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Input, InputProps } from 'reactstrap';
import { ProfessionDto, ProfessionType } from '../../entities/profession.dto';

type Props = {
	register: any;
	control: any;
	name: string;
	professionType?: ProfessionType;
	setValue: any;
	isSpecialistSignUp?: boolean;
	isSpecialistProfile?: boolean;
	isPlaceProfile?: boolean;
} & InputProps;

export const ProfessionSelect: React.FC<Props> = ({
	register,
	control,
	name,
	professionType = 'specialist',
	setValue,
	isSpecialistSignUp = false,
	isPlaceProfile = false,
	isSpecialistProfile = false,
	...rest
}) => {
	const { data, loading, error } = useQuery(GET_MAIN_PROFESSIONS_QUERY, {
		variables: {
			type: professionType
		}
	});

	const [professions, setProfessions] = useState<ProfessionDto[]>([]);

	useEffect(() => {
		if (data && data.professions) {
			setProfessions(data.professions);
		}
	}, [data]);

	useEffect(() => {
		if (professions && professions.length > 0) {
			console.log('PROFESSIONS', professions);
			if (!rest.defaultValue) {
				setValue(name, professions[0].id);
			}
		}
	}, [professions]);

	const createHtmlId = (id: number) => {
		return `${name}-${id}`;
	};

	if (!data) {
		return (
			<Input type='hidden' {...register(name)} value={rest.defaultValue} />
		);
	}

	return (
		<Controller
			name={name}
			control={control}
			render={({ field: { value, onChange } }) => (
				<DropdownWrapper
					withSearch
					placeholder='Wyszukaj profesję...'
					getStringForFilter={(p: ProfessionDto) => p.name}
					optionsWrapperClassname={classNames({
						'md:ml-2': isSpecialistProfile || isPlaceProfile
					})}
					toggleComponent={
						<div className='py-1'>
							{professions.find((p) => p.id === parseInt(value))?.name}
						</div>
					}
					toggleClassname={classNames('border', {
						'md:ml-2 mb-2 md:mb-0': isSpecialistProfile || isPlaceProfile
					})}
					wrapperClassname={classNames({
						'col-span-4': isSpecialistSignUp
					})}
					options={professions}
					onOptionClick={(profession: ProfessionDto) => onChange(profession.id)}
					getOptionDisplay={(profession: ProfessionDto) => profession.name}
				/>
			)}
		/>
	);
};
