import { useQuery } from '@apollo/client';
import {
	GET_MAIN_PROFESSIONS_QUERY,
	GET_SECONDARY_PROFESSIONS_QUERY
} from 'gql/professions';
import React, { useEffect, useState } from 'react';
import { FormGroup, Input, InputProps, Label } from 'reactstrap';
import { gql } from '../../common/apollo/apollo-client';
import { ProfessionDto, ProfessionType } from '../../entities/profession.dto';

type Props = {
	register: any;
	parentId: number | null;
	name: string;
	professionType?: ProfessionType;
} & InputProps;

export const ProfessionsMulti: React.FC<Props> = ({
	register,
	parentId,
	name,
	professionType = 'specialist',
	...rest
}) => {
	const { data, loading, error } = useQuery(
		parentId ? GET_SECONDARY_PROFESSIONS_QUERY : GET_MAIN_PROFESSIONS_QUERY,
		{
			variables: {
				parent_id: parentId,
				type: professionType
			}
		}
	);

	const [professions, setProfessions] = useState<ProfessionDto[]>([]);

	useEffect(() => {
		if (data && data.professions) {
			setProfessions(data.professions);
		}
	}, [data]);

	const createHtmlId = (id: number) => {
		return `${name}-${id}`;
	};

	return (
		<div className='md:grid grid-cols-4 col-span-3 md:px-2 mb-4'>
			{professions.map((profession) => (
				<FormGroup key={profession.id} check inline className='pt-3'>
					<Input
						type='checkbox'
						className='cursor-pointer mr-1'
						id={createHtmlId(profession.id)}
						value={profession.id}
						{...register(name)}
					/>
					<Label
						check
						htmlFor={createHtmlId(profession.id)}
						className='cursor-pointer'
					>
						{profession.name}
					</Label>
				</FormGroup>
			))}
		</div>
	);
};
