import { gql } from '@apollo/client';

export const GET_MAIN_PROFESSIONS_QUERY = gql`
	query get_main_professions($type: String!) {
		professions(
			where: { parent_id: { _is_null: true }, type: { _eq: $type } }
			order_by: { name: asc }
		) {
			id
			name
			parent_id
			slug
			updated_at
			is_active
			created_at
		}
	}
`;

export const GET_SECONDARY_PROFESSIONS_QUERY = gql`
	query get_main_professions($parent_id: Int!, $type: String!) {
		professions(
			where: { parent_id: { _eq: $parent_id }, type: { _eq: $type } }
		) {
			id
			name
			type
			parent_id
			slug
			updated_at
			is_active
			created_at
		}
	}
`;
