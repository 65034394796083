import { useSessionContext } from "common/session/session-context";
import React, { useState } from "react";
import { FiFlag } from "react-icons/fi";
import { Button } from "reactstrap";
import { AdminFormModal } from "./admin-form-modal";

type Props = {
  context: any;
  className?: string
};

export const AdminFormLink: React.FC<Props> = ({
  context,
  children,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && (
        <AdminFormModal
          onClose={() => setIsOpen(false)}
          context={context}
        />
      )}
      <a href="#" className={className} onClick={() => setIsOpen(true)}>
        {children}
      </a>
    </>
  );
};
