import classNames from 'classnames';
import React, { ReactElement, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
	breadCrumb: String;
	title: String;
	className?: String;
	description?: string;
	helperComponent?: ReactElement;
};

export const PanelBodyWrapper: React.FC<Props> = ({
	breadCrumb,
	title,
	description,
	helperComponent,
	className,
	children
}) => {
	const [showHelpModal, setShowHelpModal] = useState(false);

	const toggleHelperModal = () => setShowHelpModal(!showHelpModal);

	return (
		<div className='text-sm pb-40 min-h-screen px-3 lg:px-8'>
			<div className='flex flex-row'>
				<p className='flex-1 text-xs text-gray-500 py-3.5 mb-2 mt-16 mr-4 rounded-r-lg'>
					{breadCrumb}
				</p>
			</div>
			<div className='text-xl pt-8 text-gray-900'>{title}</div>
			<div className='pb-8 text-xs text-gray-500'>
				{description}
				{!!helperComponent && (
					<>
						<br className='md:hidden' />
						<a
							type='button'
							role='button'
							className={classNames('text-blue-700 hover:underline text-sm', {
								'md:ml-3': !!description
							})}
							onClick={toggleHelperModal}
						>
							Dowiedz się więcej {'>'}
						</a>
					</>
				)}
			</div>
			<div className={`text-sm ${className}`}>{children}</div>
			{showHelpModal && (
				<Modal isOpen={true} className='back-office mobile-fullscreen'>
					<ModalHeader toggle={toggleHelperModal}>
						<span className='ml-3'>{title}</span>
					</ModalHeader>
					<ModalBody>{helperComponent}</ModalBody>
					<ModalFooter>
						<Button
							className='ml-auto'
							color='primary'
							onClick={toggleHelperModal}
						>
							Rozumiem
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</div>
	);
};
