import classNames from 'classnames';
import { formatServiceMoney } from 'common/formatters/money';
import React from 'react';

type Props = {
	price: string;
	priceTo?: number;
	priceNegotiable?: boolean;
	hasDiscount?: boolean;
};

export const Price: React.FC<Props> = ({
	price,
	hasDiscount = false,
	priceTo = null,
	priceNegotiable = false
}) => {
	if (priceNegotiable) {
		return <span className='text-right'>Do negocjacji</span>;
	}

	if (!!priceTo) {
		return hasDiscount ? (
			<div className='inline text-right'>
				<span className='ml-1'>
					<span
						className={classNames({
							' line-through text-xs': hasDiscount
						})}
					>
						{`Od ${formatServiceMoney(price)} do ${formatServiceMoney(
							priceTo.toString()
						)}`}
					</span>
					<br className='' />
					<span>
						Od&nbsp;{formatServiceMoney(price, hasDiscount)}
						<span className='md:hidden xl:inline'>&nbsp;</span>
						<br className='hidden md:inline xl:hidden' />
						do&nbsp;
						{formatServiceMoney(priceTo.toString(), hasDiscount)}
					</span>
				</span>
			</div>
		) : (
			<span>
				{`Od ${formatServiceMoney(price)} do ${formatServiceMoney(
					priceTo.toString()
				)}`}
			</span>
		);
	}

	return (
		<div className='inline text-right'>
			{hasDiscount && parseFloat(price) > 0 ? (
				<>
					<span
						className={classNames({
							' line-through text-xs': hasDiscount
						})}
					>
						{formatServiceMoney(price)}
					</span>
					<span className='ml-1'>{formatServiceMoney(price, hasDiscount)}</span>
				</>
			) : (
				<span className='ml-1'>{formatServiceMoney(price, hasDiscount)}</span>
			)}
		</div>
	);
};
