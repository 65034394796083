import { gql, useMutation } from '@apollo/client';
import { formatHours } from 'common/formatters/format-hours';
import { formatServiceMoney } from 'common/formatters/money';
import { formatSimpleAddress } from 'common/formatters/simple-address';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { createRoute, RoutesEnum } from 'common/routes';
import { useSessionContext } from 'common/session/session-context';
import { translate, useTranslate } from 'common/translate/translate';
import { yup, yupResolver } from 'common/yup';
import dayjs from 'dayjs';
import { EventDto } from 'entities/event.dto';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
	Button,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from 'reactstrap';

type Props = {
	isBySpecialist?: boolean;
	event: EventDto;
	onCancel: () => void;
};

type FormValues = {
	first_name: string;
	last_name: string;
	phone: string;
	email: string;
} & any;

const JOIN_EVENT_MUTATION = gql`
	mutation join_event(
		$email: String!
		$event_id: Int!
		$first_name: String!
		$last_name: String!
		$phone: String!
		$user_id: Int!
	) {
		join_event(
			email: $email
			event_id: $event_id
			first_name: $first_name
			last_name: $last_name
			phone: $phone
			user_id: $user_id
		) {
			success
		}
	}
`;

const JOIN_EVENT_AND_SIGN_UP = gql`
	mutation join_event_and_sign_up(
		$email: String = ""
		$event_id: Int = 10
		$first_name: String = ""
		$is_accepting_marketing: Boolean = false
		$last_name: String = ""
		$phone: String = ""
	) {
		join_event_and_sign_up(
			email: $email
			event_id: $event_id
			first_name: $first_name
			is_accepting_marketing: $is_accepting_marketing
			last_name: $last_name
			phone: $phone
		) {
			email
			first_name
			id
			last_name
			phone
			role
			success
		}
	}
`;

const validationSchema = yup.object({});

const validationSchemaWithSignUp = yup.object({
	first_name: yup.string().required().label(translate('"imię"')),
	last_name: yup.string().required().label(translate('"nazwisko"')),
	phone: yup.string().required().label(translate('"telefon"')),
	email: yup.string().email().required().label(translate('"email"')),
	acceptPolicy: yup
		.string()
		.is(
			['true'],
			translate('Musisz zaakceptować politykę prywatności i regulamin')
		)
});

export const JoinEventModal: React.FC<Props> = ({
	isBySpecialist = false,
	event,
	onCancel
}) => {
	const t = useTranslate();
	const router = useRouter();
	const userSession = useSessionContext();

	const [showModal, setShowModal] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		setError,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(
			userSession.accessToken ? validationSchema : validationSchemaWithSignUp
		)
	});

	const fields = watch();

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const [
		joinEvent,
		{ data: joinData, error: joinError, loading: joinLoading }
	] = useMutation(JOIN_EVENT_MUTATION);

	const [
		signUpAndJoin,
		{ data: signData, error: signError, loading: signLoading }
	] = useMutation(JOIN_EVENT_AND_SIGN_UP);

	useEffect(() => {
		if (!!joinData) {
			if (!joinData.join_event.success) {
				setShowModal(true);
			} else {
				window.location.reload();
			}
		}
	}, [joinData]);

	useEffect(() => {
		if (!!signData && !!signData.insert_events_users_one) {
			(window as any).location = createRoute(RoutesEnum.Customer_MyEvents);
		}

		if (!!signData && signData.join_event_and_sign_up.success) {
			(window as any).location = createRoute(
				RoutesEnum.Customer_ReservationConfirmRequired
			);
		}

		if (!!signData && !signData.join_event_and_sign_up.success) {
			(window as any).location = createRoute(RoutesEnum.Customer_EventFull);
		}
	}, [signData]);

	const onSubmit = (values: FormValues) => {
		const data = {
			user_id: isBySpecialist ? null : userSession?.session?.id,
			first_name:
				isBySpecialist || !userSession.session
					? values.first_name
					: userSession.session?.first_name,
			last_name:
				isBySpecialist || !userSession.session
					? values.last_name
					: userSession.session?.last_name,
			phone:
				isBySpecialist || !userSession.session
					? values.phone
					: userSession.session?.phone,
			email:
				isBySpecialist || !userSession.session
					? values.email
					: userSession.session?.email
		};

		const variables = {
			...data,
			event_id: event.id
		};

		if (!userSession.session) {
			signUpAndJoin({ variables });
		} else {
			joinEvent({
				variables
			});
		}
	};

	return (
		<>
			{showModal && (
				<Modal
					size='sm'
					isOpen={true}
					className='back-office mobile-fullscreen'
				>
					<ModalHeader toggle={() => setShowModal(false)}></ModalHeader>
					<ModalBody>
						Nie udało się zapisać na wydarzenie - w trakcie dołączania do
						wydarzenia zostało zarezerwowane ostatnie miejsce
					</ModalBody>
					<ModalFooter>
						<Button
							color='primary'
							className='ml-auto'
							onClick={() => setShowModal(false)}
						>
							Rozumiem
						</Button>
					</ModalFooter>
				</Modal>
			)}

			<Modal isOpen={true} size='lg' className='back-office mobile-fullscreen'>
				<ModalHeader toggle={() => onCancel()}>
					<div className='ml-4'>Dołącz do wydarzenia</div>
				</ModalHeader>
				<ModalBody>
					{' '}
					<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Data wydarzenia: {dayjs(event.event_at).format('DD.MM.YYYY')}
					</h3>
					<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Godzina: {dayjs(event.event_at).format('HH:mm')}
					</h3>
					<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Adres: {formatSimpleAddress(event.addressess)}
					</h3>
					<FormGroup>
						<Label>{t('Nazwa wydarzenia')}</Label>
						<Label>{event.name}</Label>
					</FormGroup>
					<FormGroup>
						<Label>{t('Czas trwania')}</Label>
						<Label>{formatHours(event.timespan)}</Label>
					</FormGroup>
					<FormGroup>
						<Label>{t('Cena')}</Label>
						<Label>{formatServiceMoney(event.price)}</Label>
					</FormGroup>
					<FormGroup>
						<Label>{t('Opis')}</Label>
						<Label>{event.description ?? 'Brak opisu'}</Label>
					</FormGroup>
					<FormGroup>
						<Label>{t('Pozostało miejsc')}</Label>
						<Label>
							{event.capacity - event.events_users_aggregate.aggregate.count} z{' '}
							{event.capacity}
						</Label>
					</FormGroup>
					{(!userSession.accessToken ||
						!userSession.session ||
						isBySpecialist) && (
						<>
							<FormGroup>
								<Label>{t('Imię*')}</Label>
								<Input
									required
									type='text'
									invalid={isInvalid('first_name')}
									{...customRegister('first_name')}
								/>
								<FormFeedback>{getError('first_name')}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label>{t('Nazwisko*')}</Label>
								<Input
									type='text'
									invalid={isInvalid('last_name')}
									{...customRegister('last_name')}
								/>
								<FormFeedback>{getError('last_name')}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label>{t('Telefon *')}</Label>
								<Input
									type='text'
									invalid={isInvalid('phone')}
									{...customRegister('phone')}
								/>
								<FormFeedback>{getError('phone')}</FormFeedback>
							</FormGroup>
							<FormGroup>
								<Label>{t('Email *')}</Label>
								<Input
									type='email'
									invalid={isInvalid('email')}
									{...customRegister('email')}
								/>
								<FormFeedback>{getError('email')}</FormFeedback>
							</FormGroup>
							{!isBySpecialist && (
								<FormGroup check inline className='pt-3'>
									<div className='col-span-3 flex px-4'>
										<Input
											type='checkbox'
											{...customRegister('is_accepting_marketing')}
											className='cursor-pointer block mr-2'
											id='acceptPolicy'
											invalid={isInvalid('acceptPolicy')}
										/>
										<Label
											check
											htmlFor='acceptPolicy'
											className='cursor-pointer'
										>
											{t(
												'Akceptuję regulamin świadczenia usług przez Znany.pl. Zapoznałem się z informacją o przetwarzaniu danych osobowych. *'
											)}
										</Label>
									</div>
									<FormFeedback className='d-block'>
										{getError('acceptPolicy')}
									</FormFeedback>
								</FormGroup>
							)}
							{!isBySpecialist && (
								<FormGroup>
									<div className='col-span-3 flex items-start place-items-start px-4 mt-2'>
										<Input
											type='checkbox'
											{...customRegister('is_accepting_marketing')}
											className='cursor-pointer block mr-2'
											id='is_accepting_marketing'
										/>
										<Label
											check
											htmlFor='is_accepting_marketing'
											className='cursor-pointer block col-start-2'
										>
											{t(
												'Wyrażam zgodę na otrzymywanie materiałów promocyjnych od Znany.pl'
											)}
										</Label>
									</div>
								</FormGroup>
							)}
							{signError && (
								<FormGroup>
									<div className='p-2 m-2 col-span-3 text-sm bg-red-800 text-white rounded'>
										{signError.message ===
										'Uniqueness violation. duplicate key value violates unique constraint "users_email_key"'
											? translate(
													'Istnieje już konto zarejestrowane na podany adres email. Spróbuj użyć innego adresu email. Jeśli to Twoje konto, to spróbuj się zalogować'
											  )
											: signError.message}
									</div>
								</FormGroup>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => onCancel()}>
						Anuluj
					</Button>
					<Button color='primary' onClick={handleSubmit(onSubmit)}>
						Dołącz
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
