import React from "react";
import { EntityRadio } from "./entity-radio";

type Props = {
  register: any;
  name: string;
};

export const EntityRadioGroup: React.FC<Props> = ({ register, name }) => {
  return (
    <div className="flex gap-2 text-white">
      <EntityRadio register={register} name={name} value="specialist">
        Specjaliści
      </EntityRadio>
      |
      <EntityRadio register={register} name={name} value="place">
        Placówki
      </EntityRadio>
      |
      <EntityRadio register={register} name={name} value="event">
        Wydarzenia
      </EntityRadio>
    </div>
  );
};
