import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { yup } from 'common/yup';
import { toastActions, ToastContext } from 'components/toasts/toasts-context';
import { UserDto } from 'entities/user.dto';
import React, { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
	Button,
	Form,
	FormFeedback,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader
} from 'reactstrap';
import { PersonOnList } from './person-on-list';

type Props = {
	user: UserDto;
	onClose: () => void;
};

const schema = yup.object({
	content: yup.string().required().label('"Treść wiadomości"')
});

type FormValues = {
	content: string;
};

const SEND_MESSAGE_MUTATION = gql`
	mutation send_message($content: String!, $recipient_id: Int!) {
		send_message(content: $content, recipient_id: $recipient_id) {
			author_id
			channel_id
			content
			created_at
			deleted_at
			id
			type
			updated_at
		}
	}
`;

export const SendMessagePopup: React.FC<Props> = ({ user, onClose }) => {
	if (!user) {
		return null;
	}

	const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(schema)
	});

	const [sendMessage, { data, loading, error }] = useMutation(
		SEND_MESSAGE_MUTATION
	);

	const onSubmit = (values: FormValues) => {
		sendMessage({
			variables: {
				content: values.content,
				recipient_id: user.id
			}
		});
	};

	const toastContext = useContext(ToastContext);

	useEffect(() => {
		if (data) {
			toastContext.dispatch(
				toastActions.show({
					type: 'success',
					message: 'Twoja wiadomość została wysłana'
				})
			);

			onClose();
		} else if (error) {
			toastContext.dispatch(toastActions.showUnexpectedError());
		}
	}, [data, loading, error]);

	const reactstrapRegister = createReactstrapRegister<FormValues>(register);
	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(
		errors,
		(t) => t
	);

	return (
		<Modal isOpen={true} className='back-office mobile-fullscreen' size='sm'>
			<Form onSubmit={handleSubmit(onSubmit)}>
				<ModalHeader toggle={() => onClose()}>
					<span className='mx-4'>Wysyłanie wiadomości</span>
				</ModalHeader>
				<ModalBody>
					<div className='flex flex-col p-3 ml-3 mr-2 rounded-lg bg-blue-100 mb-4'>
						<h2 className='font-bold text-md pb-3'>Wiadomość do</h2>
						<PersonOnList isActive={false} user={user} onClick={() => {}} />
					</div>
					<FormGroup>
						<Label>Treść wiadomości</Label>
						<Input
							invalid={isInvalid('content')}
							type='textarea'
							{...reactstrapRegister('content')}
						/>
						<FormFeedback error>{getError('content')}</FormFeedback>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<Button type='button' onClick={() => onClose()}>
						Anuluj
					</Button>
					<Button type='submit' color='primary'>
						Wyślij wiadomość
					</Button>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
