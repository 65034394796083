import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import locale from 'dayjs/locale/pl';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(relativeTime);

// Initialize locale here
dayjs.locale(locale);
dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);

export { dayjs };
