import classNames from 'classnames';
import { createRoute, RoutesEnum } from 'common/routes';
import { Stars } from 'components/front';
import { globallyDefaultAvatarUrl } from 'components/my-account/specialist/public-profile-edition';
import { SpecialistDto } from 'entities/specialist.dto';
import { UserDto } from 'entities/user.dto';
import Link from 'next/link';
import React from 'react';

type Props = {
	user: UserDto;
	justAvatar?: boolean;
};

export const UserPanelCard: React.FC<Props> = ({
	user,
	justAvatar = false
}) => {
	const { id, first_name, last_name } = user;

	return (
		<>
			<div className={classNames('flex items-start', { 'mb-3': !justAvatar })}>
				<span>
					<img
						className={`mr-2`}
						width={40}
						height={40}
						src={globallyDefaultAvatarUrl}
						alt={'Klient ' + first_name + ' ' + last_name}
						style={{ objectFit: 'cover' }}
					/>
				</span>
				{!justAvatar && (
					<div className='flex-1'>
						<p className={`items-center font-medium`}>
							{first_name} {last_name}
						</p>
						<p className={` text-gray-500 text-sm`}>Klient</p>
					</div>
				)}
			</div>
		</>
	);
};
