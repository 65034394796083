import { dayjs } from 'common/dayjs';
import { Dayjs } from 'dayjs';

const weekdaysTranslation = ['Pn', 'Wt', 'Śr', 'Czw', 'Pt', 'So', 'Ndz'];
const fullWeekdaysTranslation = [
	'Poniedziałek',
	'Wtorek',
	'Środa',
	'Czwartek',
	'Piątek',
	'Sobota',
	'Niedziela'
];

export const formatWeekDay = (date: Dayjs, fullDayName: boolean = false) => {
	if (date.isSame(dayjs(), 'date')) {
		return 'Dziś';
	} else if (date.isSame(dayjs().add(1, 'day'), 'date')) {
		return 'Jutro';
	}

	let validWeekday = date.day() - 1;

	if (validWeekday < 0) {
		validWeekday = 6;
	}

	return (fullDayName ? fullWeekdaysTranslation : weekdaysTranslation)[
		validWeekday
	];
};
