import classNames from 'classnames';
import { hasPremiumBasedOnUser } from 'common/premium/has-premium-based-on-expiration-date';
import { createRoute, RoutesEnum } from 'common/routes';
import { PremiumSmallIcon } from 'components/premium-plugs/premium-small-icon';
import { PlaceDto } from 'entities/place.dto';
import Link from 'next/link';
import React from 'react';

type Props = {
	place: PlaceDto;
	avatarWidth?: number;
	withLink?: boolean;
	justAvatar?: boolean;
	centerName?: boolean;
};

export const Verified: React.FC<{
	id: number | undefined;
}> = ({ id }) => {
	return (
		<span
			className='inline-block relative ml-1'
			data-tip='Konto Premium'
			data-for={id?.toString()}
		>
			<PremiumSmallIcon
				className='w-4.5 h-4.5 relative top-0.5'
				fontSize={16}
			/>
			{/* <HiStar className="absolute text-green-600 top-0 -left-0.5" style={{ zIndex: 1 }} fontSize={25}/> */}
		</span>
	);
};

export const PlacePanelCard: React.FC<Props> = ({
	place,
	avatarWidth = 80,
	withLink = true,
	justAvatar = false,
	centerName = false
}) => {
	const { id, avatar_url, name, professions_places, user } = place;

	const hasPremium = hasPremiumBasedOnUser(user);

	return (
		<div
			className={classNames('flex', {
				'items-center': centerName,
				'items-start': !centerName
			})}
		>
			{avatar_url && withLink && (
				<Link
					href={createRoute(RoutesEnum.Front_Place, {
						id
					})}
				>
					<a title={`Link do profilu placówki ${name}`}>
						<img
							className={`mr-2 rounded-md`}
							width={avatarWidth}
							height={avatarWidth}
							src={avatar_url}
							alt={'Placówka ' + name}
							style={{ objectFit: 'cover' }}
						/>
					</a>
				</Link>
			)}
			{!withLink && (
				<span>
					<img
						className={`mr-2 rounded-md`}
						width={avatarWidth}
						height={avatarWidth}
						src={avatar_url}
						alt={'Placówka ' + name}
						style={{ objectFit: 'cover' }}
					/>
				</span>
			)}
			{!justAvatar && (
				<div className='flex-1'>
					{id !== 0 && withLink ? (
						<Link
							href={createRoute(RoutesEnum.Front_Place, {
								id
							})}
						>
							<a className={`items-center font-medium`}>
								{name}
								{hasPremium && <Verified id={user.id} />}
							</a>
						</Link>
					) : (
						<p className={`items-center font-medium`}>
							{name} {hasPremium && <Verified id={user.id} />}
						</p>
					)}
					<p className={` text-gray-500 text-sm`}>
						{professions_places?.map((item) => item.profession.name).join(', ')}
					</p>
					{/* {stars != 0 && <Stars stars={stars!} opinions={opinions!} onClick={() => {
          router.push("/specjalista/" + id + "?tab=opinie");
        }} />} */}
				</div>
			)}
		</div>
	);
};
