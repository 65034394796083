import React from "react";

type Props = React.InputHTMLAttributes<HTMLInputElement>;

export const Slider: React.FC<Props> = ({ ...rest }) => {
  return (
    <div className="relative">
      <input
        type="range"
        className="
        form-range
        focus:outline-none focus:ring-0 focus:shadow-none
        w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer
      "
        {...rest}
      />
    </div>
  );
};
