import { getBase64FromFile } from 'common/images/transformation';
import { ButtonWithSpinner } from 'components/button-with-spinner/button-with-spinner.component';
import { Slider } from 'components/form/slider';
import React, { useCallback, useState } from 'react';
import Cropper, { Area, Point } from 'react-easy-crop';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getCroppedImg, getRotatedImage } from './canvas-operations';

type Props = {
	src: string;
	onCancel: () => void;
	onSave: (base64: string) => void;
};

export const CropModal: React.FC<Props> = ({ src, onSave, onCancel }) => {
	const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [areaPixels, setAreaPixels] = useState<any>(null);
	const [isSaving, setIsSaving] = useState<boolean>(false);

	const onCropComplete = useCallback(
		(croppedArea: Area, croppedAreaPixels: Area) => {
			console.log(croppedArea, croppedAreaPixels);

			setAreaPixels(croppedAreaPixels);
		},
		[]
	);

	const onSaveClick = async () => {
		setIsSaving(true);

		const croppedImage = await getCroppedImg(src, areaPixels);

		try {
			if (croppedImage) {
				const base64 = await getBase64FromFile(croppedImage);

				if (base64) {
					onSave(base64.toString());
				} else {
					throw 'cant save';
				}
			} else {
				throw 'cant crop';
			}
		} catch (error) {
			setIsSaving(false);
		}
	};

	return (
		<Modal isOpen={true} className='back-office mobile-fullscreen' size='lg'>
			<ModalHeader toggle={onCancel}>Przytnij zdjęcie</ModalHeader>
			<ModalBody>
				<div
					className='max-w-full relative mx-auto'
					style={{ width: 350, height: 350 }}
				>
					<Cropper
						image={src}
						crop={crop}
						zoom={zoom}
						aspect={1}
						onCropChange={setCrop}
						onCropComplete={onCropComplete}
						onZoomChange={setZoom}
					/>
				</div>
				<div className='mx-auto mt-5 md:w-96'>
					<Slider
						value={zoom}
						min={1}
						max={3}
						step={0.1}
						aria-labelledby='Zoom'
						onChange={(event) => setZoom(Number(event.target.value))}
					/>
				</div>
			</ModalBody>
			<ModalFooter>
				<Button onClick={onCancel}>Anuluj</Button>
				<ButtonWithSpinner
					color='primary'
					onClick={onSaveClick}
					isSpinning={isSaving}
				>
					{!isSaving && 'Zapisz'}
				</ButtonWithSpinner>
			</ModalFooter>
		</Modal>
	);
};
