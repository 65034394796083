import classNames from 'classnames';
import { createRoute, RoutesEnum } from 'common/routes';
import Image from 'next/image';
import Link from 'next/link';
import { FaFacebook, FaInstagram } from 'react-icons/fa';

interface Props {
	home?: boolean;
}

export const Footer: React.FC<Props> = ({ home = false }) => {
	return (
		<div
			className={classNames('text-gray-500', {
				'bg-gray-50': home
			})}
		>
			<div
				className={`
       py-10
       block
       md:grid
       grid-cols-5
       px-5
       md:px-0
       md:w-11/12 xl:w-10/12
       mx-auto
       text-sm`}
			>
				<div className={`col-span-2`}>
					<Image
						className={``}
						layout={'fixed'}
						width={130}
						height={30}
						src={'/znany-logo1.png'}
					/>
					<p>Już dzisiaj znajdź znanego specjalistę</p>
					<div className='mt-2 flex gap-3'>
						<a
							href='https://www.facebook.com/znanypl-101896526014300'
							target='_blank'
							rel='noreferrer'
							style={{ fontSize: 30 }}
							className='hover:text-blue-600'
						>
							<FaFacebook />
						</a>
						<a
							href='https://www.instagram.com/znany.pl/'
							target='_blank'
							rel='noreferrer'
							style={{ fontSize: 30 }}
							className='hover:text-blue-600'
						>
							<FaInstagram />
						</a>
					</div>
				</div>
				<div>
					<h4
						className={`
            pt-4
           font-bold
           pb-4
				
        `}
					>
						Serwis
					</h4>
					<Link href={`/polityka-cookie`}>
						<a className={` block  items-center`}>Cookies</a>
					</Link>
					<Link href={`/polityka-prywatnosci`}>
						<a className={` block  items-center`}>
							Polityka <br className='hidden md:block lg:hidden' />
							Prywatności
						</a>
					</Link>
					<Link href={`/regulamin`}>
						<a className={` block  items-center`}>Regulamin</a>
					</Link>
				</div>
				<div>
					<h4
						className={`
          pt-4
           font-bold
           pb-4
        `}
					>
						Dla użytkownika
					</h4>

					<Link href={`/kontakt`}>
						<a className={` block  items-center`}>Kontakt</a>
					</Link>
					<Link href={`/specjalisci?specjalizacja=&miasto=&online=nie`}>
						<a className={` block  items-center`}>Specjaliści</a>
					</Link>
				</div>
				<div>
					<h4
						className={`
            pt-4
            font-bold
            pb-4
            `}
					>
						Dla specjalistów
					</h4>
					<Link href={`/zapytaj-specjaliste`}>
						<a className={` block  items-center`}>Pytania i odpowiedzi</a>
					</Link>
					<Link href={`/rejestracja-specjalisty`}>
						<a className={` block  items-center`}>Rejestracja specjalisty</a>
					</Link>
					<Link href={`/rejestracja-placowki`}>
						<a className={` block  items-center`}>Rejestracja placówek</a>
					</Link>
					<Link href={createRoute(RoutesEnum.Pricing)}>
						<a className={` block items-center`}>Cennik</a>
					</Link>
				</div>
			</div>
			<div
				className={`
       bg-white
       text-center
       p-6
    `}
			>
				Copyright © 2022 Znany.pl - znajdź i umów wizytę ze znanym specjalistą
			</div>
		</div>
	);
};
