import classNames from 'classnames';
import {
	hasPremiumBasedOnUser,
	hasPremiumBasedOnUserSession
} from 'common/premium/has-premium-based-on-expiration-date';
import { createRoute, RoutesEnum } from 'common/routes';
import { Stars } from 'components/front';
import { PremiumSmallIcon } from 'components/premium-plugs/premium-small-icon';
import dayjs from 'dayjs';
import { SpecialistDto } from 'entities/specialist.dto';
import Link from 'next/link';
import React from 'react';

export const Verified: React.FC<{
	id: number | undefined;
}> = ({ id }) => {
	return (
		<span
			className='inline-block relative ml-1'
			data-tip='Konto Premium'
			data-for={id?.toString()}
		>
			<PremiumSmallIcon
				className='w-4.5 h-4.5 relative top-0.5'
				fontSize={16}
			/>
			{/* <HiStar className="absolute text-green-600 top-0 -left-0.5" style={{ zIndex: 1 }} fontSize={25}/> */}
		</span>
	);
};

type Props = {
	specialist: SpecialistDto;
	showStars?: boolean;
	withLink?: boolean;
	justAvatar?: boolean;
	noMarginBottom?: boolean;
};

const deletedSpecialist = {
	id: 0,
	avatar_url:
		'https://hobo-file-storage.herokuapp.com/image/znany-pl/avatar_1393f8c4-106a-46c3-a334-e3a208d387cc.jpg',
	profession: {
		id: 0,
		name: '-----',
		slug: '',
		updated_at: dayjs().format(),
		created_at: dayjs().format(),
		is_active: false,
		type: 'specialist'
	},
	shown_name: 'KONTO USUNIĘTE',
	opinions_aggregate: {
		aggregate: {
			count: 0,
			avg: {
				rating: 0
			}
		}
	},
	user: {
		payment_expiration_at: dayjs().format(),
		id: 0,
		is_deleted: true
	}
};

export const SpecialistPanelCard: React.FC<Props> = ({
	specialist,
	showStars,
	withLink = true,
	justAvatar = false,
	noMarginBottom = false
}) => {
	const { id, avatar_url, profession, shown_name, opinions_aggregate, user } =
		specialist ?? deletedSpecialist;

	const hasPremium = hasPremiumBasedOnUser(user);

	return (
		<>
			<div
				className={classNames('flex items-start', {
					'mb-3': !noMarginBottom
				})}
			>
				{withLink && !user.is_deleted && avatar_url && (
					<Link
						href={createRoute(RoutesEnum.Front_Specialist, {
							id
						})}
					>
						<a title={`Link do profilu specialisty ${shown_name}`}>
							<img
								className={`mr-2 rounded-md`}
								width={40}
								height={40}
								src={avatar_url}
								alt={'Specjalista ' + shown_name}
								style={{ objectFit: 'cover' }}
							/>
						</a>
					</Link>
				)}
				{(!withLink || user.is_deleted) && (
					<span>
						<img
							className={`mr-2 rounded-md`}
							width={40}
							height={40}
							src={avatar_url}
							alt={'Specjalista ' + shown_name}
							style={{ objectFit: 'cover' }}
						/>
					</span>
				)}
				{!justAvatar && (
					<div className='flex-1'>
						{id !== 0 && withLink ? (
							<Link
								href={createRoute(RoutesEnum.Front_Specialist, {
									id
								})}
							>
								<a className={`items-center font-medium`}>
									{shown_name} {hasPremium && <Verified id={user.id} />}
								</a>
							</Link>
						) : (
							<p className={`items-center font-medium`}>
								{shown_name} {hasPremium && <Verified id={user.id} />}
							</p>
						)}
						<p className={` text-gray-500 text-sm`}>{profession?.name}</p>
					</div>
				)}
			</div>
			{showStars && <Stars stars={opinions_aggregate.aggregate.avg.rating} />}
		</>
	);
};
