import { formatSimpleAddress } from 'common/formatters/simple-address';
import { createGoogleMapsLink } from 'common/maps/create-google-maps-link';
import { FiHome, FiDollarSign, FiVideo } from 'react-icons/fi';
interface Props {
	address: any;
}
export const Addresses: React.FC<Props> = ({ address }) => {
	return (
		<>
			<div className={`flex gap-1`}>
				{address.is_online ? <FiVideo /> : <FiHome />}
				<span>{formatSimpleAddress(address)}</span>
				{!address.is_online && (
					<span>
						{' '}
						<a
							className='pl-4 text-blue-600 hover:underline'
							href={createGoogleMapsLink(address)}
							target='_blank'
							rel='noreferrer'
						>
							Mapa
						</a>
					</span>
				)}
			</div>
			<div className={`flex gap-1 mt-2`}></div>
		</>
	);
};
