import classNames from "classnames";
import React from "react";
import { HiStar } from "react-icons/hi";

type Props = {
  fontSize?: number;
  className?: string;
};

export const PremiumSmallIcon: React.FC<Props> = ({
  fontSize = 20,
  className = "w-6 h-6",
}) => {
  return (
    <div
      className={classNames(
        "bg-blue-600 text-white flex-column rounded-full mr-2 justify-center items-center",
        className
      )}
    >
      <div className="flex flex-row -rotate-12">
        <HiStar fontSize={fontSize} />
      </div>
    </div>
  );
};
