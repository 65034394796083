export const formatHours = (hours: number) => {
	const hoursForString = Math.floor(hours);
	const roundedMinutes = Math.round((hours - hoursForString) * 60);

	const properHours = hoursForString + Number(roundedMinutes === 60);
	const properMinutes = roundedMinutes - Number(roundedMinutes === 60) * 60;

	let returnString = '';

	if (hours > 1) {
		returnString += `${properHours} godz.`;

		if (properMinutes !== 0) {
			returnString += ` ${properMinutes} min.`;
		}
	} else {
		returnString = `${properMinutes} min.`;
	}

	return returnString;
};
