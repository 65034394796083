export const formatServiceMoney = (num: string, hasDiscount = false) => {
	const regex = /([a-zA-złąęćóżź])/;

	if (num && num.toString().match(regex) == null) {
		return (hasDiscount ? calculateDiscountedPrice(num) : num) + ' zł';
	}

	return num;
};

export const calculateDiscountedPrice = (price: string) => {
	const discount = parseFloat(
		process.env.NEXT_PUBLIC_PREMIUM_DISCOUNT as string
	);

	const newPrice = parseFloat(price) - parseFloat(price) * (discount / 100);

	return newPrice.toString().includes('.')
		? newPrice.toFixed(2)
		: newPrice.toString();
};

export const formatMoney = (num: number) => {
	return num.toLocaleString('pl-PL', {
		style: 'currency',
		currency: 'PLN'
	});
};
