import { UserSessionDto } from "common/session/handle-session";
import dayjs from "dayjs";
import { UserDto } from "entities/user.dto";

export const hasPremiumBasedOnUser = (user?: UserDto) => {
  const paymentExpirationAt =
    user && user?.payment_expiration_at
      ? dayjs(user.payment_expiration_at)
      : null;

  if (!paymentExpirationAt) {
    return false;
  }

  const isPremium = dayjs().isBefore(paymentExpirationAt);

  return isPremium;
};

export const hasPremiumBasedOnUserSession = (userSession: UserSessionDto) => {
  const paymentExpirationAt =
    userSession && userSession?.session?.payment_expiration_at
      ? dayjs(userSession?.session?.payment_expiration_at)
      : null;

  if (!paymentExpirationAt) {
    return false;
  }

  const isPremium = dayjs().isBefore(paymentExpirationAt);

  return isPremium;
};
