import classNames from 'classnames';
import { createReactstrapRegister } from 'common/forms/registration';
import { useOnClickOutside } from 'common/use-on-click-outside';
import React, { ReactElement, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiChevronDown, FiChevronUp, FiSearch } from 'react-icons/fi';
import { Input } from 'reactstrap';

type Props = {
	disabled?: boolean;
	toggleComponent: ReactElement;
	toggleClassname?: any;
	options: any[];
	optionClassname?: any;
	optionsWrapperClassname?: any;
	optionComponent?: ReactElement;
	onOptionClick?: (option: any) => void;
	createOptionClassname?: (option: any) => any;
	getOptionDisplay?: (option: any) => any;
	wrapperClassname?: any;
	withSearch?: boolean;
	placeholder?: string;
	getStringForFilter?: (option: any) => any;
};

export const DropdownWrapper: React.FC<Props> = ({
	disabled = false,
	onOptionClick,
	toggleComponent,
	options,
	toggleClassname,
	optionClassname,
	createOptionClassname,
	optionComponent,
	getOptionDisplay = (option) => option,
	wrapperClassname,
	optionsWrapperClassname,
	withSearch = false,
	placeholder = 'Wyszukaj...',
	getStringForFilter = (option) => option
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const { register, watch, setValue } = useForm<{ phrase: string }>({
		defaultValues: { phrase: '' }
	});

	const phrase = watch('phrase');

	const customRegister = createReactstrapRegister(register);

	const ref = useRef<any>();
	useOnClickOutside(ref, () => {
		setIsOpen(false);
		setValue('phrase', '');
	});

	const onChoose = (option: any) => {
		onOptionClick?.(option);
		setIsOpen(false);
		setValue('phrase', '');
	};

	return (
		<div className={classNames('relative', wrapperClassname)} ref={ref}>
			<a
				onClick={disabled ? () => null : () => setIsOpen(!isOpen)}
				className={classNames(
					'bg-white p-2 flex rounded justify-between',
					toggleClassname,

					{
						'text-gray-200 ': disabled,
						'cursor-pointer': !disabled
					}
				)}
			>
				<div>{toggleComponent}</div>
				<div className='mr-1 flex items-center text-lg'>
					{isOpen ? <FiChevronUp /> : <FiChevronDown />}
				</div>
			</a>
			{isOpen && (
				<div
					style={{ maxHeight: withSearch ? '240px' : '194px' }}
					className={classNames(
						'absolute bg-white w-full rounded border-2 cursor-pointer z-10  overflow-y-scroll customscroll',
						optionsWrapperClassname
					)}
				>
					{withSearch && (
						<div className=' sticky top-0'>
							<Input
								placeholder={placeholder}
								className='w-full py-2'
								type='text'
								{...customRegister('phrase')}
							/>
							<FiSearch size={16} className='absolute right-3 top-3' />
						</div>
					)}
					{options
						.filter((o) =>
							!withSearch
								? true
								: getStringForFilter(o).toLowerCase().includes(phrase)
						)
						.map(
							(option) =>
								optionComponent ?? (
									<div
										onClick={() => onChoose(option)}
										className={classNames(
											'p-2 border-y hover:bg-gray-100',
											optionClassname,
											createOptionClassname?.(option)
										)}
									>
										{getOptionDisplay(option)}
									</div>
								)
						)}
				</div>
			)}
		</div>
	);
};
