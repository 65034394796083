import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatSimpleAddress } from 'common/formatters/simple-address';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { useSessionContext } from 'common/session/session-context';
import { translate, useTranslate } from 'common/translate/translate';
import { yup } from 'common/yup';
import { ButtonWithSpinner } from 'components/button-with-spinner/button-with-spinner.component';
import {
	intervals,
	sortIntervals
} from 'components/calendar-settings/single-calendar-day-settings';
import { DropdownWrapper } from 'components/dropdown/dropdown-wrapper';
import { selectStyle } from 'components/my-account/specialist/reservations-calendar';
import { Dayjs } from 'dayjs';
import { AddressDto } from 'entities/address.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import {
	Form,
	FormGroup,
	Label,
	Input,
	FormFeedback,
	Card,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button,
	Col,
	InputGroup,
	Row
} from 'reactstrap';

type Props = {
	date: Dayjs;
	addressId: number;
	onCancel: () => void;
	specialist: SpecialistDto;
};

const INSERT_BREAK = gql`
	mutation insert_break(
		$reservation_timespan: float8!
		$reservation_at: timestamptz!
		$reservation_place: String!
		$specialist_id: Int!
		$address_id: Int!
	) {
		insert_reservations_one(
			object: {
				reservation_timespan: $reservation_timespan
				reservation_place: $reservation_place
				reservation_at: $reservation_at
				price: "0"
				specialist_id: $specialist_id
				address_id: $address_id
				is_accepted: true
				is_break: true
			}
		) {
			id
		}
	}
`;

const validationSchema = yup.object({
	address: yup.number().positive().required(),
	reservation_timespan: yup.string().required('Wybierz czas trwania przerwy')
});

type FormValues = {
	id?: string;
	address: string;
	service: string;
	additional_info: string;
} & any;

export const AddBreakModal: React.FC<Props> = ({
	date,
	addressId,
	specialist,
	onCancel
}) => {
	const t = useTranslate();

	const {
		register,
		handleSubmit,
		watch,
		setError,
		control,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			address: addressId.toString()
		}
	});

	const fields = watch();

	const allAddresses = [
		...specialist.addressesses,
		...specialist.calendar_settings_by_specialist_id
			.map((item) => item.addressess)
			.flat()
	];

	const currentAddress = allAddresses.find((x) => x.id === addressId);

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const [saveReservation, { data, loading, error }] = useMutation(INSERT_BREAK);

	const onSubmit = (values: FormValues) => {
		const variables = {
			reservation_at: date.toDate(),
			price: '0',
			reservation_timespan: values.reservation_timespan,
			specialist_id: specialist.id,
			address_id: currentAddress!.id,
			reservation_place: formatSimpleAddress(currentAddress as AddressDto)
		};

		saveReservation({
			variables
		});
	};

	useEffect(() => {
		if (!!data) {
			window.location.reload();
		}
	}, [data]);

	return (
		<Modal isOpen={true} size='lg' className='back-office'>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<ModalHeader className='pl-4' toggle={() => onCancel()}>
					<div className='ml-4'>Dodaj przerwę</div>
				</ModalHeader>
				<ModalBody>
					<h3 className='text-bold text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Wybrany termin: {date.format('DD.MM.YYYY')}
					</h3>
					<h3 className='text-bold text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Godzina: {date.format('HH:mm')}
					</h3>
					{currentAddress ? (
						<h3 className='text-bold text-xl p-4 bg-gray-100 rounded-lg mb-4'>
							Adres: {formatSimpleAddress(currentAddress)}
						</h3>
					) : null}

					<FormGroup>
						<Label>{t('Czas trwania *')}</Label>

						<Controller
							control={control}
							name='reservation_timespan'
							render={({ field: { value, onChange } }) => (
								<DropdownWrapper
									wrapperClassname='col-span-3 md:col-span-2 mb-3'
									toggleClassname='border'
									toggleComponent={<span>{intervals[value]}</span>}
									options={sortIntervals()}
									onOptionClick={([timespan, label]) => onChange(timespan)}
									getOptionDisplay={([timespan, label]) => label}
								/>
							)}
						/>

						<FormFeedback>{getError('reservation_timespan')}</FormFeedback>
					</FormGroup>

					<FormGroup className='mr-5 mt-2'>
						{/* <Label>{t("Adres *")}</Label> */}
						<Input
							className='col-span-2 w-full block px-2 ml-3.5'
							type='hidden'
							invalid={isInvalid('address')}
							{...customRegister('address')}
						>
							{specialist.addressesses.map((address) => {
								return (
									<option key={address.id} value={address.id}>
										{formatSimpleAddress(address)}
									</option>
								);
							})}
						</Input>
						<FormFeedback>{getError('address')}</FormFeedback>
					</FormGroup>
				</ModalBody>
				<ModalFooter>
					<ButtonWithSpinner
						className='ml-auto'
						color='primary'
						type='submit'
						isSpinning={loading}
					>
						{t('Dodaj przerwę')}
					</ButtonWithSpinner>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
