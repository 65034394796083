import { AvatarUpload } from "components/avatar-upload/avatar-upload";
import React from "react";
import { Controller } from "react-hook-form";

type Props = {
  control: any;
  name: string;
};

export const AvatarUploadControl: React.FC<Props> = ({ control, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <AvatarUpload
            avatarUrl={field.value}
            onChange={(newValue: string) =>
              field.onChange({
                target: {
                  value: newValue,
                },
              })
            }
          />
        );
      }}
    />
  );
};
