import dayjs, { Dayjs } from 'dayjs';
import { ReservationDto } from 'entities/reservation.dto';

export type DaySettings = {
	enabled: boolean;
	start: string;
	end: string;
	interval: string;
	breaks: {
		from: number;
		to: number;
	}[];
};

function roundNearest5(num: number) {
	return Math.round(num / 5) * 5;
}

export const generateEvents = (
	date: Dayjs,
	daySettings: DaySettings,
	reservations: ReservationDto[],
	addressId: number,
	userSessionId: number | undefined,
	isBySpecialist: boolean
) => {
	const events = [];

	const now = dayjs();

	if (
		daySettings.start === null ||
		daySettings.start === undefined ||
		daySettings.start === '' ||
		!daySettings.end ||
		!daySettings.interval
	) {
		return [];
	}

	let parsedStart, parsedEnd, parsedInterval;

	try {
		parsedStart = parseFloat(daySettings.start);
		parsedEnd = parseFloat(daySettings.end);
		parsedInterval = parseFloat(daySettings.interval);
	} catch {
		return [];
	}

	if (
		parsedStart === null ||
		parsedStart === undefined ||
		!parsedEnd ||
		!parsedInterval
	) {
		return [];
	}

	for (let i = parsedStart; i < parsedEnd; i += parsedInterval) {
		const clonedDate = date.clone();

		let canShow = true;
		let isEvent = false;
		let event;

		const idk = roundNearest5(i * 60);
		const o = clonedDate.set('hours', idk / 60).set('minutes', idk % 60);
		const newEvent = o.format('HH:mm');
		const validatedDate = o.clone();

		for (let k = 0; k < daySettings.breaks.length; k++) {
			const timeBreak = daySettings.breaks[k];

			const fromHours = Math.floor(timeBreak.from);
			const fromMinutes = (timeBreak.from - fromHours) * 60;

			const toHours = Math.floor(timeBreak.to);
			const toMinutes = (timeBreak.to - toHours) * 60;

			const from = validatedDate
				.set('h', fromHours)
				.set('minutes', fromMinutes);
			const to = validatedDate.set('h', toHours).set('minutes', toMinutes);

			if (Math.abs(from.diff(validatedDate, 'm')) < 3) {
				canShow = false;
				break;
			}

			if (validatedDate.isAfter(from) && validatedDate.isBefore(to)) {
				canShow = false;
				break;
			}
		}

		if (!canShow) {
			continue;
		}

		for (let j = 0; j < reservations.length; j++) {
			const reservation = reservations[j];

			if (reservation.is_rejected) {
				continue;
			}

			const reservationDate = dayjs(reservation.reservation_at).startOf(
				'minute'
			);

			if (
				!!reservation.event_id &&
				o.startOf('minute').valueOf() === reservationDate.valueOf() &&
				addressId.toString() === reservation.event?.address_id?.toString() &&
				(!reservation.event.events_users.some(
					(e) => e.user_id?.toString() === userSessionId?.toString()
				) ||
					!!isBySpecialist) &&
				reservation.event.capacity >
					reservation.event.events_users_aggregate.aggregate.count
			) {
				isEvent = true;
				event = reservation.event;
			}

			const endReservationDate = dayjs(reservation.reservation_at).add(
				reservation.reservation_timespan,
				'hours'
			);
			const difference = Math.abs(
				validatedDate.diff(reservationDate, 'minutes')
			);

			if (difference < 3) {
				canShow = false;
				break;
			}

			if (
				validatedDate.isAfter(reservationDate) &&
				validatedDate.isBefore(endReservationDate)
			) {
				canShow = false;
				break;
			}
		}

		if (now.isAfter(validatedDate)) {
			canShow = false;
		}

		if (canShow) {
			events.push({
				display: newEvent,
				date: o
			});
		} else if (isEvent) {
			events.push({
				display: newEvent,
				date: o,
				isEvent,
				event
			});
		} else {
			events.push({
				display: newEvent,
				date: o,
				isCovered: true
			});
		}
	}

	return events;
};
