import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { formatHours } from 'common/formatters/format-hours';
import { calculateDiscountedPrice } from 'common/formatters/money';
import { formatSimpleAddress } from 'common/formatters/simple-address';
import { createReactstrapRegister } from 'common/forms/registration';
import { validationHandlers } from 'common/forms/validation-handlers';
import { hasPremiumBasedOnUserSession } from 'common/premium/has-premium-based-on-expiration-date';
import { createRoute, RoutesEnum } from 'common/routes';
import { useSessionContext } from 'common/session/session-context';
import { translate, useTranslate } from 'common/translate/translate';
import { yup } from 'common/yup';
import { ButtonWithSpinner } from 'components/button-with-spinner/button-with-spinner.component';
import { DropdownWrapper } from 'components/dropdown/dropdown-wrapper';
import { Price } from 'components/front/price.component';
import dayjs, { Dayjs } from 'dayjs';
import { ServiceDto } from 'entities/service.dto';
import { SpecialistDto } from 'entities/specialist.dto';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Form,
	FormGroup,
	Label,
	Input,
	FormFeedback,
	Modal,
	ModalBody,
	ModalHeader,
	ModalFooter,
	Button
} from 'reactstrap';

type Props = {
	specialist: SpecialistDto;
	date: Dayjs;
	addressId: number;
	isBySpecialist?: boolean;
	isByPlace?: boolean;
	onCancel: () => void;
	serviceId?: number;
	dayEndHour?: any;
	isEmbed?: boolean;
	onEmbedFinish?: () => void;
};

const RESERVE_AND_SIGN_UP = gql`
	mutation reserve_and_sign_up(
		$email: String!
		$first_name: String!
		$phone: String!
		$reservation_timespan: float8!
		$is_accepting_marketing: Boolean!
		$last_name: String!
		$reservation_at: timestamptz!
		$reservation_place: String!
		$service: String!
		$price: String!
		$specialist_id: Int!
		$address_id: Int!
		$additional_info: String
	) {
		reserve_and_sign_up(
			email: $email
			first_name: $first_name
			phone: $phone
			reservation_timespan: $reservation_timespan
			is_accepting_marketing: $is_accepting_marketing
			last_name: $last_name
			reservation_at: $reservation_at
			reservation_place: $reservation_place
			service: $service
			price: $price
			specialist_id: $specialist_id
			address_id: $address_id
			additional_info: $additional_info
		) {
			id
			email
			first_name
			last_name
			phone
			role
		}
	}
`;

const ADD_CUSTOMER_RESERVATION = gql`
	mutation insert_reservation(
		$reservation_timespan: float8!
		$reservation_place: String!
		$reservation_at: timestamptz!
		$service: String!
		$price: String!
		$specialist_id: Int!
		$address_id: Int!
		$first_name: String!
		$last_name: String!
		$phone: String!
		$email: String!
		$additional_info: String
	) {
		add_customer_reservation(
			reservation: {
				reservation_timespan: $reservation_timespan
				reservation_place: $reservation_place
				reservation_at: $reservation_at
				service: $service
				price: $price
				specialist_id: $specialist_id
				address_id: $address_id
				is_accepted: true
				first_name: $first_name
				last_name: $last_name
				phone: $phone
				email: $email
				additional_info: $additional_info
			}
		) {
			id
			success
		}
	}
`;

const ADD_RESERVATION_AS_SPECIALIST = gql`
	mutation add_reservation(
		$price: String!
		$reservation_at: timestamptz!
		$reservation_timespan: float8!
		$service: String!
		$additional_info: String = ""
		$address_id: Int!
		$email: String = ""
		$first_name: String = ""
		$last_name: String = ""
		$phone: String = ""
		$reservation_place: String!
		$specialist_id: Int!
	) {
		add_reservation(
			reservation: {
				address_id: $address_id
				specialist_id: $specialist_id
				price: $price
				reservation_at: $reservation_at
				reservation_timespan: $reservation_timespan
				service: $service
				additional_info: $additional_info
				email: $email
				first_name: $first_name
				last_name: $last_name
				phone: $phone
				reservation_place: $reservation_place
			}
		) {
			success
		}
	}
`;

const validationSchema = yup.object({
	address: yup.number().positive().required(),
	service: yup.number().positive().required(),
	additional_info: yup.string().optional(),

	first_name: yup.string().optional().label(translate('"imię"')),
	last_name: yup.string().optional().label(translate('"nazwisko"')),
	phone: yup.string().optional().label(translate('"telefon"')),
	email: yup.string().email().optional().label(translate('"email"'))
});

const validationSchemaWithSignUp = yup.object({
	address: yup.number().positive().required().label('adres'),
	service: yup.number().positive().required().label('usługa'),
	additional_info: yup.string().optional().label('dodatkowe uwagi'),

	first_name: yup.string().required().label(translate('"imię"')),
	last_name: yup.string().required().label(translate('"nazwisko"')),
	phone: yup.string().required().label(translate('"telefon"')),
	email: yup.string().email().required().label(translate('"email"')),
	acceptPolicy: yup
		.string()
		.is(
			['true'],
			translate('Musisz zaakceptować politykę prywatności i regulamin')
		)
	// is_accepting_marketing: yup.string()
});

type FormValues = {
	id?: string;
	address: string;
	service: string;
	additional_info: string;
} & any;

export const ReserveModal: React.FC<Props> = ({
	specialist,
	isBySpecialist = false,
	isByPlace = false,
	date,
	addressId,
	onCancel,
	serviceId,
	dayEndHour,
	isEmbed = false,
	onEmbedFinish
}) => {
	const t = useTranslate();

	const diffInHours =
		date
			.set('hour', dayEndHour)
			.set('minutes', 0)
			.set('seconds', 0)
			.set('milliseconds', 0)
			.diff(date) /
		1000 /
		60 /
		60;

	const userSession = useSessionContext();
	const isPlace = Boolean(userSession.session?.place);

	const servicesForAddress = specialist.services.filter(
		(s) =>
			(s.addresses as string[]).includes(addressId.toString()) &&
			s.timespan <= diffInHours
	);

	const allAddresses = [
		...specialist.addressesses,
		...specialist.calendar_settings_by_specialist_id
			.map((item) => item.addressess)
			.flat()
	];

	const {
		register,
		handleSubmit,
		watch,
		setError,
		control,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(
			userSession.accessToken || isEmbed
				? validationSchema
				: validationSchemaWithSignUp
		),
		defaultValues: {
			address: addressId,
			service: serviceId ?? servicesForAddress[0]?.id
		}
	});

	const fields = watch();

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const [saveReservation, { data, loading, error }] = useMutation(
		(userSession.accessToken && userSession.session) || isEmbed
			? isBySpecialist || isEmbed
				? ADD_RESERVATION_AS_SPECIALIST
				: ADD_CUSTOMER_RESERVATION
			: RESERVE_AND_SIGN_UP,
		{
			errorPolicy: 'all'
		}
	);

	const onSubmit = (values: FormValues) => {
		const currentAdr = allAddresses.find(
			(x) => x.id.toString() === values.address.toString()
		);

		const curService = specialist.services.find(
			(x) => x.id.toString() === values.service.toString()
		);

		if (!currentAdr || !curService) {
			return;
		}

		const isByCustomer = !isBySpecialist && !isByPlace;

		saveReservation({
			variables: {
				...values,
				reservation_at: date.toDate(),
				reservation_place: formatSimpleAddress(currentAdr),
				service: curService.name,
				reservation_timespan: curService.timespan,
				price: hasDiscount
					? calculateDiscountedPrice(curService.price)
					: curService.price,
				specialist_id: specialist.id,
				is_accepting_marketing: false,
				address_id: currentAdr.id,
				first_name:
					!isByCustomer || !userSession.session
						? values.first_name
						: userSession.session?.first_name,
				last_name:
					!isByCustomer || !userSession.session
						? values.last_name
						: userSession.session?.last_name,
				phone:
					!isByCustomer || !userSession.session
						? values.phone
						: userSession.session?.phone,
				email:
					!isByCustomer || !userSession.session
						? values.email
						: userSession.session?.email,
				additional_info: values.additional_info
			}
		});
	};

	useEffect(() => {
		if (data && isEmbed) {
			onEmbedFinish?.();
			return;
		}

		if (data && (isBySpecialist || isByPlace)) {
			window.location.reload();

			return;
		}

		if (data && data.add_customer_reservation) {
			(window as any).location = createRoute(
				RoutesEnum.Customer_MyReservations
			);

			return;
		}

		if (data && data.reserve_and_sign_up) {
			// const { access_token, ...session } = data.reserve_and_sign_up;

			// setCookies(ACCESS_TOKEN_COOKIE, access_token);
			// setCookies(SESSION_COOKIE, session);

			(window as any).location = createRoute(
				RoutesEnum.Customer_ReservationConfirmRequired
			);

			return;
		}
	}, [data]);

	useEffect(() => {
		if (error) {
			if (
				error.graphQLErrors[0].message ===
				'Reservation already exists in given time'
			) {
				alert(
					'Wybrany termin u tego specjalisty został już przez kogoś zarezerwowany. Musisz wybrać inny termin.'
				);

				window.location.reload();
			}
		}
	}, [error]);

	const currentService = servicesForAddress.find(
		(x) => x.id.toString() === fields.service.toString()
	);

	const currentAddress = allAddresses.find(
		(x) => x.id.toString() === fields.address.toString()
	);

	const hasAgreementsToCheck = !isPlace && !isBySpecialist;

	const hasPremium = hasPremiumBasedOnUserSession(userSession);
	const hasDiscount = hasPremium && !isBySpecialist;

	return (
		<Modal
			isOpen={true}
			size='lg'
			className='back-office mobile-fullscreen embed'
		>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<ModalHeader toggle={() => onCancel()}>
					<div className='ml-4'>Zarezerwuj termin</div>
				</ModalHeader>
				<ModalBody>
					<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Wybrany termin: {date.format('DD.MM.YYYY')}
					</h3>
					<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
						Godzina: {date.format('HH:mm')}
					</h3>
					{currentAddress ? (
						<h3 className='text-bold text-base lg:text-xl p-4 bg-gray-100 rounded-lg mb-4'>
							Adres: {formatSimpleAddress(currentAddress)}
						</h3>
					) : null}
					<>
						{servicesForAddress.length > 0 && (
							<FormGroup className='lg:mr-1'>
								<Label>{t('Usługa *')}</Label>
								<Controller
									control={control}
									name='service'
									render={({ field: { value, onChange } }) => (
										<DropdownWrapper
											wrapperClassname='col-span-3 md:col-span-2 mb-3 md:ml-3'
											toggleClassname='border'
											toggleComponent={
												<span>
													{servicesForAddress.find((s) => s.id === value)
														?.name ?? 'Wybierz usługę'}
												</span>
											}
											options={servicesForAddress}
											onOptionClick={(service: ServiceDto) =>
												onChange(service.id)
											}
											getOptionDisplay={(service: ServiceDto) => service.name}
										/>
									)}
								/>

								<FormFeedback>
									<span className='ml-2'>
										{getError('service') && 'Proszę wybrać usługę'}
									</span>
								</FormFeedback>
							</FormGroup>
						)}
						{servicesForAddress && servicesForAddress.length === 0 && (
							<div className='md:ml-4 grid md:grid-cols-3'>
								<div>Usługa</div>
								<div className='col-span-2 my-2'>
									<strong>
										{isBySpecialist
											? 'Brak usług pod tym adresem'
											: 'Skontaktuj się ze specjalistą w celu umówienia wizyty'}
									</strong>
								</div>
							</div>
						)}
					</>
					{currentService && (
						<>
							<FormGroup>
								<Label>{t('Czas trwania usługi')}</Label>
								<Label>{formatHours(currentService.timespan)}</Label>
							</FormGroup>
							<FormGroup>
								<Label>{t('Cena')}</Label>
								<Label>
									<Price
										priceTo={currentService.price_to}
										priceNegotiable={currentService.price_negotiable}
										price={currentService.price}
										hasDiscount={hasDiscount}
									/>
								</Label>
							</FormGroup>
						</>
					)}

					<FormGroup>
						<Label>{t('Dodatkowe uwagi')}</Label>
						<Input
							type='textarea'
							invalid={isInvalid('additional_info')}
							{...customRegister('additional_info')}
						/>
						<FormFeedback>{getError('additional_info')}</FormFeedback>
					</FormGroup>

					<FormGroup className='mr-5 mt-2'>
						<Input
							className='col-span-2 w-full block px-2 ml-3.5'
							type='hidden'
							invalid={isInvalid('address')}
							{...customRegister('address')}
						>
							{allAddresses.map((address) => {
								return (
									<option key={address.id} value={address.id}>
										{formatSimpleAddress(address)}
									</option>
								);
							})}
						</Input>
						<FormFeedback>{getError('address')}</FormFeedback>
					</FormGroup>
					{(!userSession.accessToken ||
						!userSession.session ||
						isBySpecialist ||
						isPlace) && (
						<>
							<FormGroup>
								<Label>{isPlace ? t('Imię klienta*') : t('Imię*')}</Label>
								<Input
									required
									type='text'
									invalid={isInvalid('first_name')}
									{...customRegister('first_name')}
								/>
								<FormFeedback>{getError('first_name')}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label>
									{isPlace ? t('Nazwisko klienta*') : t('Nazwisko*')}
								</Label>
								<Input
									type='text'
									invalid={isInvalid('last_name')}
									{...customRegister('last_name')}
								/>
								<FormFeedback>{getError('last_name')}</FormFeedback>
							</FormGroup>

							<FormGroup>
								<Label>
									{isPlace ? t('Telefon klienta*') : t('Telefon *')}
								</Label>
								<Input
									type='text'
									invalid={isInvalid('phone')}
									{...customRegister('phone')}
								/>
								<FormFeedback>{getError('phone')}</FormFeedback>
							</FormGroup>
							<FormGroup>
								<Label>{isPlace ? t('Email klienta*') : t('Email *')}</Label>
								<Input
									type='email'
									invalid={isInvalid('email')}
									{...customRegister('email')}
								/>
								<FormFeedback>{getError('email')}</FormFeedback>
							</FormGroup>
							{hasAgreementsToCheck && (
								<FormGroup check inline className='pt-3'>
									<div className='col-span-3 flex px-4'>
										<Input
											type='checkbox'
											{...customRegister('is_accepting_marketing')}
											className='cursor-pointer block mr-2'
											id='acceptPolicy'
											invalid={isInvalid('acceptPolicy')}
										/>
										<Label
											check
											htmlFor='acceptPolicy'
											className='cursor-pointer'
										>
											{t(
												'Akceptuję regulamin świadczenia usług przez Znany.pl. Zapoznałem się z informacją o przetwarzaniu danych osobowych. *'
											)}
										</Label>
									</div>
									<FormFeedback className='d-block'>
										{getError('acceptPolicy')}
									</FormFeedback>
								</FormGroup>
							)}
							{hasAgreementsToCheck && (
								<FormGroup>
									<div className='col-span-3 flex px-4 mt-2'>
										<Input
											type='checkbox'
											{...customRegister('is_accepting_marketing')}
											className='cursor-pointer block mr-2'
											id='is_accepting_marketing'
										/>
										<Label
											check
											htmlFor='is_accepting_marketing'
											className='cursor-pointer block col-start-2'
										>
											{t(
												'Wyrażam zgodę na otrzymywanie materiałów promocyjnych od Znany.pl'
											)}
										</Label>
									</div>
								</FormGroup>
							)}
							{error && (
								<FormGroup>
									<div className='p-2 m-2 col-span-3 text-sm bg-red-800 text-white rounded'>
										{error.message ===
										'Uniqueness violation. duplicate key value violates unique constraint "users_email_key"'
											? translate(
													'Istnieje już konto zarejestrowane na podany adres email. Spróbuj użyć innego adresu email. Jeśli to Twoje konto, to spróbuj się zalogować'
											  )
											: error.message}
									</div>
								</FormGroup>
							)}
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button color='secondary' onClick={() => onCancel()}>
						Anuluj
					</Button>
					<ButtonWithSpinner
						className='ml-auto'
						color='primary'
						type='submit'
						isSpinning={loading}
					>
						{t('Zarezerwuj')}
					</ButtonWithSpinner>
				</ModalFooter>
			</Form>
		</Modal>
	);
};
