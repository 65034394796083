import classNames from 'classnames';
import { DropdownWrapper } from 'components/dropdown/dropdown-wrapper';
import { AddressDto } from 'entities/address.dto';
import { range } from 'lodash';
import React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { Button, Col, Label, Row } from 'reactstrap';

const weekdays = [
	'monday',
	'tuesday',
	'wendesday',
	'thursday',
	'friday',
	'saturday',
	'sunday'
];
const weekdaysTranslation = [
	'Poniedziałek',
	'Wtorek',
	'Środa',
	'Czwartek',
	'Piątek',
	'Sobota',
	'Niedziela'
];

type Props = {
	wd: string;
	fields: any;
	customRegister: any;
	register: any;
	index: number;
	control: any;
	address: AddressDto;
};

const durations = range(0 + 1 / 12, 12 + 1 / 12, 1 / 12);

const mapDurationToString = (duration: number) => {
	const hours = Math.floor(duration);
	const minutes = Math.round((duration - hours) * 60);

	const properHours = hours + Number(minutes === 60);
	const properMinutes = minutes - Number(minutes === 60) * 60;

	const hoursString =
		properHours === 1 ? 'godzina' : properHours < 5 ? 'godziny' : 'godzin';

	return (
		(properHours > 0 ? `${properHours} ${hoursString} ` : '') +
		(properMinutes > 0 ? `${properMinutes} minut` : '')
	);
};

export const intervals: { [key: number]: string } = durations.reduce(
	(prev, curr) => {
		return {
			...prev,
			[curr]: mapDurationToString(curr)
		};
	},
	{}
);

export const sortIntervals = () =>
	Object.entries(intervals).sort(([keyA, valueA], [keyB, valueB]) => {
		if (parseFloat(keyA) < parseFloat(keyB)) {
			return -1;
		}
		if (parseFloat(keyA) > parseFloat(keyB)) {
			return 1;
		}

		return 0;
	});

interface BreakControllerProps {
	wd: string;
	index: number;
	control: any;
	fields: any;
	remove: any;
}

const BreakController: React.FC<BreakControllerProps> = ({
	wd,
	index,
	control,
	fields,
	remove
}) => {
	return (
		<Row className='mt-3 pt-2 mb-4 border-t-2 border-gray-100'>
			<Col>Przerwa</Col>
			<Col className='mt-2 text-xs'>
				<Label>Od:</Label>
				<Controller
					name={`${wd}.breaks[${index}].from`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<DropdownWrapper
							// disabled={!!fields[wd] && !fields[wd].enabled}
							toggleComponent={
								<span>
									{value !== undefined && value !== null
										? mapFloatToHourString(value)
										: '-----'}
								</span>
							}
							toggleClassname='border'
							options={timeRange}
							onOptionClick={onChange}
							getOptionDisplay={(hour) => mapFloatToHourString(hour)}
						/>
					)}
				/>
			</Col>
			<Col className='mt-2 text-xs'>
				<Label>Do:</Label>
				<Controller
					name={`${wd}.breaks[${index}].to`}
					control={control}
					render={({ field: { value, onChange } }) => (
						<DropdownWrapper
							toggleComponent={
								<span>
									{value !== undefined && value !== null
										? mapFloatToHourString(value)
										: '-----'}
								</span>
							}
							toggleClassname='border'
							options={timeRange}
							onOptionClick={onChange}
							getOptionDisplay={(hour) => mapFloatToHourString(hour)}
						/>
					)}
				/>
			</Col>
			<Col className='mt-2'>
				<Button
					color='danger'
					type='button'
					size='sm'
					block
					onClick={() => remove(index)}
				>
					Usuń przerwę
				</Button>
			</Col>
		</Row>
	);
};

const mapFloatToHourString = (float: number) => {
	const hoursForString = Math.floor(float);
	const roundedMinutes = Math.round((float - hoursForString) * 60);

	const properHours = hoursForString + Number(roundedMinutes === 60);
	const properMinutes = roundedMinutes - Number(roundedMinutes === 60) * 60;

	return `${properHours}:${
		properMinutes > 9 ? properMinutes : '0' + properMinutes
	}`;
};

const timeRange = range(0, 24, 1 / 12);

export const SingleCalendarDaySettings: React.FC<Props> = ({
	wd,
	fields,
	customRegister,
	register,
	index,
	control,
	address
}) => {
	const {
		fields: breakFields,
		remove,
		append
	} = useFieldArray({
		control,
		name: `${wd}.breaks`
	});

	const labelClass = 'block py-4 text-gray-700 flex col-span-4';

	return (
		<Col
			key={wd}
			className={classNames('col-span-7 md:col-span-1 rounded p-4 bg-white')}
		>
			<h4>
				<div className={labelClass}>
					<div className='form-check form-switch cursor-pointer'>
						<input
							className='form-check-input appearance-none w-9 mr-2 rounded-full float-left h-5 align-top bg-no-repeat bg-contain bg-gray-300 focus:outline-none cursor-pointer shadow-sm'
							type='checkbox'
							role='switch'
							id='flexSwitchCheckDefault'
							{...register(`${wd}.enabled`)}
						/>
						<label
							className='form-check-label inline-block text-gray-800 cursor-pointer'
							htmlFor='flexSwitchCheckDefault'
						>
							{weekdaysTranslation[index]}
						</label>
					</div>
				</div>
			</h4>
			<Row className=''>
				<Col className='mt-2 text-xs'>
					<Label>Od</Label>
					<Controller
						name={`${wd}.start`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<DropdownWrapper
								toggleComponent={
									<span>
										{value !== undefined && value !== null
											? mapFloatToHourString(value)
											: '-----'}
									</span>
								}
								toggleClassname='border'
								options={timeRange}
								onOptionClick={onChange}
								getOptionDisplay={(hour) => mapFloatToHourString(hour)}
							/>
						)}
					/>
				</Col>
				<Col className='mt-2 text-xs'>
					<Label>Do</Label>
					<Controller
						name={`${wd}.end`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<DropdownWrapper
								toggleComponent={
									<span>
										{value !== undefined && value !== null
											? mapFloatToHourString(value)
											: '-----'}
									</span>
								}
								toggleClassname='border'
								options={timeRange}
								onOptionClick={onChange}
								getOptionDisplay={(hour) => mapFloatToHourString(hour)}
							/>
						)}
					/>
				</Col>
				<Col className='mt-2 text-xs'>
					<Label>Co</Label>

					<Controller
						name={`${wd}.interval`}
						control={control}
						render={({ field: { value, onChange } }) => (
							<DropdownWrapper
								toggleComponent={<span>{intervals[value]}</span>}
								toggleClassname='border'
								options={sortIntervals()}
								onOptionClick={([interval, label]) => onChange(interval)}
								getOptionDisplay={([interval, label]) => label}
							/>
						)}
					/>
				</Col>
			</Row>
			<Row className='mt-3'>
				<Col>
					<Button
						color='primary'
						type='button'
						size='sm'
						block
						outline
						onClick={() =>
							append({
								from: null,
								to: null
							})
						}
					>
						Dodaj przerwę
					</Button>
				</Col>
			</Row>
			{breakFields &&
				breakFields.map(function (breakField: any, index: number) {
					return (
						<BreakController
							wd={wd}
							index={index}
							control={control}
							fields={fields}
							remove={remove}
						/>
					);
				})}
		</Col>
	);
};
