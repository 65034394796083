export const createParameterizedUrl = (
  route: string,
  context: Record<string, any> = []
) =>
  Object.keys(context).reduce((prev, key) => {
    const regex = new RegExp(`:${key}`, "g");
    return prev.replace(regex, context[key]);
  }, route.toString());

export const createRouteTemplate = <T extends string>(
  route: T,
  context: Record<string, any> = []
) => createParameterizedUrl(route, context);


