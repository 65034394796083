export const fileStorageUrl = (process.env as any)
	.NEXT_PUBLIC_IMAGE_STORAGE_URL;

export const uploadImage = async (key: string, base64: string) => {
	const uploadResult = await fetch(fileStorageUrl, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			key,
			value: base64
		})
	});

	return uploadResult;
};
