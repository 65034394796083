/* eslint-disable @next/next/no-img-element */
import Image from "next/image";
import Link from "next/link";
import { Stars } from "components/front";
import { createRoute, RoutesEnum } from "common/routes";
import { useRouter } from "next/router";
import { ProfessionDto } from "entities/profession.dto";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import ReactTooltip from "react-tooltip";
import { PremiumSmallIcon } from "components/premium-plugs/premium-small-icon";
import classNames from "classnames";
import { createNameSlug, createSpecialistSlug } from "common/url/create-slugs";
import { SpecialistDto } from "entities/specialist.dto";

interface Props {
  id: number;
  stars: number;
  opinions: number;
  name: string;
  shown_name: string;
  profession: ProfessionDto;
  avatar_url?: any;
  secondaryProfessions: ProfessionDto[];
  colSpan: number;
  imgSize?: number;
  route: RoutesEnum;
  isPlace?: boolean;
  paymentExpirationDate: string | null;
  redirectOnAvatarClick?: boolean;
  avatarMarginRight?: boolean;
}

export const Verified: React.FC<{
  id: number | undefined;
}> = ({ id }) => {
  return (
    <span
      className="inline-block relative ml-1"
      data-tip="Konto Premium"
      data-for={id?.toString()}
    >
      <PremiumSmallIcon
        className="w-4.5 h-4.5 relative top-0.5"
        fontSize={16}
      />
      {/* <HiStar className="absolute text-green-600 top-0 -left-0.5" style={{ zIndex: 1 }} fontSize={25}/> */}
    </span>
  );
};

export const Avatar: React.FC<Partial<Props>> = ({
  id,
  name,
  shown_name,
  profession,
  avatar_url,
  stars,
  opinions,
  secondaryProfessions,
  colSpan,
  imgSize = 96,
  route = RoutesEnum.Front_Specialist,
  isPlace = false,
  paymentExpirationDate,
  redirectOnAvatarClick = false,
  avatarMarginRight = true,
}) => {
  const router = useRouter();

  const [isTruncated, setIsTruncated] = useState<boolean>(true);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const premiumSign = paymentExpirationDate ? (
    dayjs().isBefore(paymentExpirationDate) ? (
      <Verified id={id} />
    ) : null
  ) : null;

  const avatarClassname = classNames(
    "relative mt-1 w-full cursor-pointer rounded-md xl:w-24",
    {
      "xl:w-20 xl:h-20": isPlace,
      "cursor-pointer": redirectOnAvatarClick,
      "mr-3": avatarMarginRight,
    }
  );

  const slug =
    id && (shown_name || name) ? createNameSlug(shown_name! || name!, id.toString()) : "";

  return (
    <div
      className={`col-span-5 gap-3 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:flex`}
    >
      {avatar_url &&
        (redirectOnAvatarClick ? (
          <Link
            href={createRoute(route, {
              id: slug,
            })}
          >
            <img
              className={avatarClassname}
              width={imgSize}
              height={imgSize}
              src={avatar_url}
              alt={"specjalista"}
              style={{ objectFit: "cover" }}
            />
          </Link>
        ) : (
          <img
            className={avatarClassname}
            width={imgSize}
            height={imgSize}
            src={avatar_url}
            alt={"specjalista"}
            style={{ objectFit: "cover" }}
          />
        ))}
      <div className={`col-span-2 md:col-span-3 lg:col-span-3`}>
        {id !== 0 ? (
          <Link
            href={createRoute(route, {
              id: slug,
            })}
          >
            <a className={`items-center font-medium`}>
              {shown_name || name}&nbsp;{premiumSign}
            </a>
          </Link>
        ) : (
          <p className={`items-center font-medium`}>
            {shown_name || name}&nbsp;{premiumSign}
          </p>
        )}
        <p className={` text-gray-500 text-sm mb-1 mr-2`}>
          {profession?.name}{" "}
          {secondaryProfessions
            ?.filter(
              (secondaryProfession: ProfessionDto) =>
                secondaryProfession.parent_id === profession?.id
            )
            .map((el: any, i: number) => (
              <>
                <span>&#183;</span>
                <span key={el.name} className="pl-1">
                  {el.name}{" "}
                </span>
              </>
            ))}
        </p>
        {stars != 0 && (
          <Stars
            stars={stars!}
            opinions={opinions!}
            onClick={() => {
              const link = createRoute(route, {
                id,
              });

              router.push(link + "/opinie");
            }}
          />
        )}
      </div>
      {isMounted && <ReactTooltip id={id?.toString()} effect={"solid"} />}
    </div>
  );
};
