/* eslint-disable @next/next/no-img-element */
import { gql, useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { AdminFormLink } from 'components/admin-form-modal/admin-form-link';
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toastActions, ToastContext } from 'components/toasts/toasts-context';
import { Form, FormGroup, Label, Input, FormFeedback, Col } from 'reactstrap';
import { createReactstrapRegister } from '../../../common/forms/registration';
import { validationHandlers } from '../../../common/forms/validation-handlers';
import { translate, useTranslate } from '../../../common/translate/translate';
import { yup } from '../../../common/yup';
import { UserDto } from '../../../entities/user.dto';
import { ButtonWithSpinner } from '../../button-with-spinner/button-with-spinner.component';
import { ProfessionSelect } from '../../form/profession-select';
import { ProfessionsMulti } from '../../form/professions-multi';
import { GET_MY_USERS_QUERY } from 'common/get-my-users.query';
import { AvatarUploadControl } from 'components/form/avatar-upload-control';
import classNames from 'classnames';

export const globallyDefaultAvatarUrl =
	'https://hobo-file-storage.herokuapp.com/image/znany-pl/avatar_1393f8c4-106a-46c3-a334-e3a208d387cc.jpg';

type Props = {
	user: UserDto;
};

type FormValues = {
	shownName: string;
	profession: string;
	secondaryProfessions: string[];
	avatarUrl: string;
	description: string;
	experience: string;
};

const validationSchema = yup.object({
	shownName: yup.string().required().min(7).label('"wyświetlana nazwa"'),
	profession: yup.string().required()
	// secondaryProfessions: yup.string().required(),
});

const UPDATE_WITH_PROFESSIONS_REPLACEMENTS_MUTATION = gql`
	mutation update_with_professions_replacements(
		$shown_name: String!
		$profession_id: Int!
		$description: String!
		$experience: String!
		$avatar_url: String!
		$user_id: Int!
		$specialists_professions: [secondary_professions_specialists_insert_input!]!
		$specialist_id: Int!
	) {
		update_specialists(
			_set: {
				shown_name: $shown_name
				profession_id: $profession_id
				description: $description
				experience: $experience
				avatar_url: $avatar_url
			}
			where: { id: { _eq: $specialist_id } }
		) {
			affected_rows
		}
		delete_secondary_professions_specialists(
			where: { specialist: { user_id: { _eq: $user_id } } }
		) {
			affected_rows
		}
		insert_secondary_professions_specialists(
			objects: $specialists_professions
		) {
			affected_rows
		}
	}
`;

export const PublicProfileEdition: React.FC<Props> = ({ user }) => {
	const t = useTranslate();

	const {
		register,
		handleSubmit,
		watch,
		setValue,
		control,
		formState: { errors }
	} = useForm<FormValues>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			shownName: user.specialist?.shown_name,
			profession: user.specialist?.profession_id.toString(),
			secondaryProfessions:
				user.specialist?.secondary_professions_specialists.map((item) =>
					item.profession_id.toString()
				),
			description: user.specialist?.description,
			avatarUrl: user.specialist?.avatar_url,
			experience: user.specialist?.experience
		}
	});

	const defaultAvatarUrl =
		user.specialist && user.specialist.avatar_url
			? user.specialist?.avatar_url
			: globallyDefaultAvatarUrl;

	const fields = watch();

	const customRegister = createReactstrapRegister(register);

	const isInvalid = validationHandlers.createIsInvalid<FormValues>(errors);
	const getError = validationHandlers.createGetError<FormValues>(errors, t);

	const [updatePublicProfile, { data, loading, error }] = useMutation(
		UPDATE_WITH_PROFESSIONS_REPLACEMENTS_MUTATION,
		{
			errorPolicy: 'all',
			refetchQueries: [GET_MY_USERS_QUERY]
		}
	);

	const onSubmit = async (values: FormValues) => {
		updatePublicProfile({
			variables: {
				shown_name: values.shownName,
				profession_id: values.profession,
				user_id: user.id,
				description: values.description,
				avatar_url: values.avatarUrl,
				experience: values.experience,
				specialist_id: user.specialist?.id,
				specialists_professions: values.secondaryProfessions.map((item) => ({
					profession_id: item,
					specialist_id: user.specialist?.id
				}))
			}
		});
	};

	const toastContext = useContext(ToastContext);
	useEffect(() => {
		if (data && !error) {
			toastContext.dispatch(
				toastActions.show({
					type: 'success',
					message: 'Informacje ogólne zostały zaktualizowane',
					duration: 8000
				})
			);
		} else if (!loading && error) {
			toastContext.dispatch(toastActions.showUnexpectedError());
		}
	}, [data, error, loading]);

	const labelClass = 'block pb-2  md:py-4 text-gray-700 font-medium';
	const formGroupClass =
		'md:grid grid-cols-3 lg:grid-cols-4 border-b border-gray-100 pr-1 bg-opacity-50';
	const formFeedbackClass = 'px-2 text-xs col-start-2 col-span-3 text-red-600';
	const inputClass = 'mb-2 md:mx-2 w-full col-span-3';

	return (
		<Col>
			<Form onSubmit={handleSubmit(onSubmit)} noValidate>
				<FormGroup className={formGroupClass}>
					<Label className={labelClass}>{t('Zdjęcie profilowe')}</Label>

					<div className='md:px-2 pb-2 col-span-2'>
						<AvatarUploadControl control={control} name='avatarUrl' />
					</div>
				</FormGroup>

				<FormGroup
					className={`${formGroupClass} rounded-tr-lg overflow-hidden pt-px border-t`}
				>
					<Label className={`pt-6 ${labelClass}`}>
						{t('Wyświetlana nazwa profilu *')}
					</Label>
					<Input
						className={inputClass}
						type='text'
						invalid={isInvalid('shownName')}
						{...customRegister('shownName')}
					/>
					<FormFeedback className={`${formFeedbackClass}`}>
						{getError('shownName')}
					</FormFeedback>
				</FormGroup>

				<FormGroup className={formGroupClass}>
					<Label className={labelClass}>{t('Opis')}</Label>
					<Input
						className={inputClass}
						type='textarea'
						rows={5}
						invalid={isInvalid('description')}
						{...customRegister('description')}
					/>
					<FormFeedback className={`${formFeedbackClass}`}>
						{getError('description')}
					</FormFeedback>
				</FormGroup>

				<FormGroup className={formGroupClass}>
					<Label className={labelClass}>{t('Doświadczenie')}</Label>
					<Input
						className={inputClass}
						type='textarea'
						rows={5}
						invalid={isInvalid('experience')}
						{...customRegister('experience')}
					/>
					<FormFeedback className={`${formFeedbackClass}`}>
						{getError('experience')}
					</FormFeedback>
				</FormGroup>

				<FormGroup className={formGroupClass.replace('pr-1', 'pr-0')}>
					<Label className={labelClass}>{t('Główna profesja *')}</Label>
					<div className='col-span-3 -mr-1'>
						<ProfessionSelect
							isSpecialistProfile
							control={control}
							setValue={setValue}
							register={customRegister}
							defaultValue={fields.profession}
							name='profession'
						/>
					</div>
					<FormFeedback className={`${formFeedbackClass}`}>
						{getError('profession')}
					</FormFeedback>
				</FormGroup>

				<FormGroup className={formGroupClass}>
					<Label className={labelClass}>{t('Specjalizacje')}</Label>
					<AdminFormLink
						className='text-blue-600 md:ml-2 col-span-2 xl:col-span-3 py-4'
						context={{
							Typ: 'Nie ma mojej specjalizacji',
							Miejsce: 'Edycja publicznego profilu specjalisty'
						}}
					>
						Nie ma Twojej specjalizacji? Napisz do nas
					</AdminFormLink>
				</FormGroup>

				<FormGroup className={formGroupClass}>
					<div></div>
					<ProfessionsMulti
						parentId={parseInt(fields.profession, 10)}
						register={customRegister}
						name='secondaryProfessions'
					/>
					<FormFeedback className={`${formFeedbackClass}`}>
						{getError('secondaryProfessions')}
					</FormFeedback>
				</FormGroup>
				{error && (
					<FormGroup error className='px-4'>
						<div className='p-2 m-2 col-span-3 text-sm bg-red-800 text-white rounded'>
							{error.message ===
							'Uniqueness violation. duplicate key value violates unique constraint "users_email_key"'
								? translate(
										'Istnieje już konto zarejestrowane na podany adres email. Spróbuj użyć innego adresu email. Jeśli to Twoje konto, to spróbuj się zalogować'
								  )
								: error.message}
						</div>
					</FormGroup>
				)}

				<FormGroup className='pt-3 flex justify-end pr-6 mb-10'>
					<ButtonWithSpinner color='primary' type='submit' isSpinning={loading}>
						{t('Zapisz')}
					</ButtonWithSpinner>
				</FormGroup>
			</Form>
		</Col>
	);
};
