import { EventDto } from "entities/event.dto";
import { PlaceDto } from "entities/place.dto";
import { SpecialistDto } from "entities/specialist.dto";

export const createNameSlug = (name: string, id: string) => {
  return (
    name.replaceAll(" ", "-").toLowerCase() +
    "-" +
    id
  );
};

export const createSpecialistSlug = (specialist: SpecialistDto) => {
  return (
    specialist.shown_name.replaceAll(" ", "-").toLowerCase() + "-" + specialist.id
  );
}

export const createPlaceSlug = (place: PlaceDto) => {
    return place.name.replaceAll(" ", "-").toLowerCase() + "-" + place.id;
};


export const createEventSlug = (event: EventDto) => {
    return event.name.replaceAll(" ", "-").toLowerCase() + "-" + event.id;
};

export const parseUriSlug = (uri: string) => {
  const splitted = uri.split('-');

  return splitted[splitted.length - 1];
}
