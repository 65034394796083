import classNames from 'classnames';
import { createRoute, RoutesEnum } from 'common/routes';
import { useSessionContext } from 'common/session/session-context';
import { UserDto } from 'entities/user.dto';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { FiMail } from 'react-icons/fi';
import { Button } from 'reactstrap';
import { SendMessagePopup } from './send-message-popup';

type Props = {
	outline?: boolean;
	user: UserDto;
	className?: string;
};

export const SendMessageButton: React.FC<Props> = ({
	user,
	outline = false,
	className = ''
}) => {
	const [isTexting, setIsTexting] = useState<boolean>(false);
	const userSession = useSessionContext();
	const router = useRouter();

	return (
		<>
			<Button
				outline={outline}
				onClick={() => {
					if (userSession.accessToken) {
						setIsTexting(true);
					} else {
						router.push(createRoute(RoutesEnum.Customer_SignUp));
					}
				}}
				color={outline ? 'secondary' : 'primary'}
				className={classNames(
					`gap-2 text-xs md:text-base
flex justify-center border px-4 py-2 rounded 
items-center bg-white`,
					className
				)}
			>
				<FiMail />
				<span>Wyślij wiadomość</span>
			</Button>
			{isTexting && (
				<SendMessagePopup onClose={() => setIsTexting(false)} user={user} />
			)}
		</>
	);
};
