/* eslint-disable @next/next/no-html-link-for-pages */
import classNames from 'classnames';
import { TopBar } from './TopBar';
import { createRoute, RoutesEnum } from 'common/routes';
import { useSessionContext } from 'common/session/session-context';
import { useOnClickOutside } from 'common/use-on-click-outside';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { BsHouseDoor } from 'react-icons/bs';
import { FaUserTie } from 'react-icons/fa';
import { FiChevronDown, FiMapPin, FiMenu, FiUser, FiX } from 'react-icons/fi';
import { Button } from 'reactstrap';
import { HamburgerMenu } from './hamburger-menu';
import {useLocation} from 'react-router';

type Props = {
	home?: boolean;
	withBg?: boolean;
	withGradient?: boolean;
};

export const MainHeader: React.FC<Props> = ({
	home,
	withBg = true,
	withGradient = true
}) => {
	const userSession = useSessionContext();

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
	const [isLoginMenuOpen, setIsLoginMenuOpen] = useState(false);

	const [isScrolled, setIsScrolled] = useState(false);

	const ref = useRef<any>();
	useOnClickOutside(ref, () => {
		setIsLoginMenuOpen(false);
	});

	useEffect(() => {
		document.addEventListener('scroll', () => {
			setIsScrolled(window.scrollY > 0);
		});
	}, []);

	return (
    <div className="fixed top-0 z-50 lg:relative">
      {home && (
        <TopBar className="hidden lg:block">
          <p className="text-center px-3 pt-3 pb-4">
            <strong className="text-blue-600">Uwaga specjalisto!!!</strong> Nie
            zwlekaj, zarejestruj się teraz, a otrzymasz{" "}
            <strong className="text-blue-600">
              darmowe konto PREMIUM na 12 miesięcy
            </strong>
            . Szczegóły w zakładce{" "}
            <Link href="/pricing">
              <strong className="text-blue-600" style={{ cursor: "pointer" }}>
                CENNIK
              </strong>
            </Link>
            .
          </p>
        </TopBar>
      )}

      <div className="relative">
        {withGradient && (
          <Image
            className={`z-50`}
            layout="fill"
            objectFit="cover"
            src={"/bg.svg"}
            priority
            alt={""}
          />
        )}
        <div className="lg:mx-auto lg:mb-0 lg:w-11/12 xl:w-10/12 lg:flex font-medium text-gray-600 bg-transparent items-center lg:p-1 w-full z-50 text-sm fixed lg:relative">
          <div
            className={`lg:w-2/12 lg:mb-0 text-center lg:text-left p-2 lg:p-0 ${
              withBg || isScrolled || isMenuOpen
                ? "bg-blue-600"
                : "bg-transparent"
            } lg:bg-transparent  w-full flex  justify-between`}
          >
            <Link href={`/`} passHref>
              <a className="flex justify-center items-center p-1 px-2 md:px-6 lg:px-0">
                <Image
                  className={`cursor-pointer`}
                  layout={"fixed"}
                  width={145}
                  height={35}
                  src={"/znany-logo.png"}
                />
              </a>
            </Link>

            <a
              role="button"
              className="ml-auto lg:hidden text-white "
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <FiX size={40} /> : <FiMenu size={40} />}
            </a>
          </div>

          <div
            className={classNames(
              "lg:relative lg:flex w-full lg:w-auto lg:flex-row flex-col flex-1 lg:bg-transparent lg:py-1 items-center justify-end gap-2 lg:gap-2 xl:gap-4 ",
              {
                "flex bg-blue-600 py-10": isMenuOpen,
                hidden: !isMenuOpen,
              }
            )}
          >
            <Link href={createRoute(RoutesEnum.Front_Specialists)}>
              <a
                className={`w-full py-4 lg:py-1 text-center lg:w-auto block items-center text-white`}
              >
                Specjaliści
              </a>
            </Link>
            <Link href={createRoute(RoutesEnum.Front_Auctions)}>
              <a
                className={`w-full py-4 lg:py-1 text-center lg:w-auto block items-center text-white`}
              >
                Licytacje
              </a>
            </Link>
            <Link href={createRoute(RoutesEnum.Front_Places)}>
              <a
                className={`w-full py-4 lg:py-1 text-center lg:w-auto block items-center text-white`}
              >
                Placówki
              </a>
            </Link>

            <Link href={createRoute(RoutesEnum.Front_Events)}>
              <a
                className={`w-full py-4 lg:py-1 text-center lg:w-auto block items-center text-white`}
              >
                Wydarzenia
              </a>
            </Link>

            {
              <Link href={`/zapytaj-specjaliste`}>
                <a
                  className={`w-full py-4 lg:py-1 text-center lg:w-auto block items-center text-white`}
                >
                  Zapytaj specjalistę
                </a>
              </Link>
            }
            {!userSession ||
              (!userSession.accessToken && (
                <>
                  <Link href={"/zaloguj-sie"}>
                    <Button
                      color="primary"
                      outline
                      tag="a"
                      className=" border-transparent"
                    >
                      Zaloguj się
                    </Button>
                  </Link>

                  <Button
                    color="primary"
                    className=" flex items-center font-medium border-transparent"
                    aria-label="Login"
                    aria-haspopup="true"
                    onClick={() => setIsLoginMenuOpen(!isLoginMenuOpen)}
                  >
                    Zarejestruj się
                    <div className="pl-3">
                      <FiChevronDown />
                    </div>
                  </Button>
                  {isLoginMenuOpen && (
                    <div
                      className="absolute lg:top-9 -bottom-20 lg:bottom-auto"
                      style={{ zIndex: 50 }}
                    >
                      <ul
                        className=" p-1.5 mt-2  text-gray-600 bg-white border  rounded-lg shadow-xl min-w-max-content z-50"
                        aria-label="submenu"
                        ref={ref}
                      >
                        <li className="mb-2 last:mb-0">
                          <Link href={createRoute(RoutesEnum.Customer_SignUp)}>
                            <span className="inline-flex items-center justify-left cursor-pointer w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-50 hover:text-blue-600">
                              <FiUser className="mr-2" />
                              Jako użytkownik
                            </span>
                          </Link>
                        </li>
                        <li className="mb-2 last:mb-0">
                          <Link
                            href={createRoute(RoutesEnum.Specialist_SignUp)}
                          >
                            <span className="inline-flex items-center justify-left cursor-pointer w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-50 hover:text-blue-600">
                              <FaUserTie className="mr-2 " />
                              Jako specjalista
                            </span>
                          </Link>
                        </li>
                        <li className="mb-2 last:mb-0">
                          <Link href={"/rejestracja-placowki"}>
                            <span className="inline-flex items-center justify-left cursor-pointer w-full px-2 py-1 text-sm transition-colors duration-150 rounded-md hover:bg-gray-50 hover:text-blue-600">
                              <BsHouseDoor className="mr-2" />
                              Jako placówka
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  )}
                </>
              ))}

            {userSession &&
              userSession.accessToken &&
              userSession?.session?.role !== "place" && (
                <Link href={"/my-account"} passHref>
                  <Button color="primary" tag={"a"}>
                    Moje konto
                  </Button>
                </Link>
              )}

            {userSession &&
              userSession.accessToken &&
              userSession?.session?.role === "place" && (
                <Link href={"/place-account"}>
                  <a
                    className={`
            block px-5 py-3 text-white
             rounded-lg items-center bg-blue-600 hover:bg-blue-700`}
                  >
                    <span>Konto placówki</span>
                  </a>
                </Link>
              )}
          </div>
        </div>
      </div>
      <div className="block h-12 lg:h-0" />
    </div>
  );
};
