type Props = {
	noPadding?: boolean;
	bg: string;
};

export const PageFullWrapper: React.FC<Props> = ({
	children,
	bg,
	noPadding = false
}) => {
	return (
		<div className={`${bg} flex-1 ${noPadding ? '' : 'px-2'}`}>{children}</div>
	);
};
