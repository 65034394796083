import { createRouteTemplate } from './url/routes';

export enum RoutesEnum {
  SignIn = "/zaloguj-sie",

  Front_Specialist = `/specjalista/:id`,
  Front_Specialists = "/specjalisci",
  Front_SpecialistsParametrized = "/specjalisci/:category/:city",

  Front_Specialists_With_Profession = "/specjalisci/:professionSlug",

  Specialist_SignUp = "/specialist-sign-up",
  Specialist_SignUpProfile = "/specialist-sign-up/profile",
  Specialist_SignUpConfirmRequired = "/sign-up/confirm-required",
  Specialist_Calendar = "/my-account/specialist/calendar",
  Specialist_Opinion = "/my-account/specialist/opinions",
  Specialist_Addresses = "/my-account/specialist/addresses",
  Specialist_Auctions = "/my-account/specialist/auctions",
  Specialist_AuctionsArchive = "/my-account/specialist/auctions/archive",
  Specialist_Chats = "/my-account/specialist/chats",
  Specialist_Payments = "/my-account/payments/payments",
  Specialist_PaymentSuccess = `/my-account/payments/payment-success`,
  Specialist_PaymentFailed = `/my-account/payments/payment-failed`,
  Specialist_PaymentVariants = "/my-account/payments/payment-variants",
  Specialist_OneTimePayment = "/my-account/payments/one-time-payment",
  Specialist_SubscriptionPayment = "/my-account/payments/subscription-payment",
  Specialist_Places = "/my-account/specialist/places",

  Customer_SignUp = "/zarejestruj-sie",
  Customer_ReservationConfirmRequired = "/sign-up/reservation-confirm-required",
  Customer_EventFull = "/sign-up/event-full",

  Customer_MyReservations = "/my-account/customer/my-reservations",
  Customer_MyPastReservations = "/my-account/customer/my-past-reservations",
  Customer_MyQuestion = "/my-account/customer/my-questions",
  Customer_MyOpinions = "/my-account/customer/my-opinions",
  Customer_FavoriteSpecialists = "/my-account/customer/favorite-specialists",
  Customer_CurrentAuctions = "/my-account/customer/auctions/current",
  Customer_FinishedAuctions = "/my-account/customer/auctions/finished",
  Customer_MyEvents = "/my-account/customer/my-events",
  Customer_MyPastEvents = "/my-account/customer/my-past-events",

  MyAccount = "/my-account",

  Place_Account = "/place-account",
  Place_Specialists = "/place-account/place-specialists",
  Place_AddSpecialists = "/place-account/place-add-specialists",
  Place_PublicProfile = "/place-account/public-profile",
  Place_Addresses = "/place-account/addresses",
  Place_Calendars = "/place-account/calendars",
  Places_Invitations = "/place-account/invitations",
  Place_Gallery = "/place-account/gallery",

  SignUpConfirmRequired = "/zarejestruj-sie/wymagana-aktywacja",
  SignUpConfirmed = "/zarejestruj-sie/potwierdzenie",
  Front_ForgotPassword = "/zapomnialem-hasla",
  Front_PasswordIsReset = "/zapomnialem-hasla/potwierdzenie",
  Front_Auctions = "/licytacje",

  Specialist_ChangePassword = "/my-account/specialist/change-password",
  MyAccount_ChangePassword = "/my-account/change-password",

  Specialist_Account = "/my-account/specialist/my-account",
  Specialist_ReservationSettings = "/my-account/specialist/reservations-settings",
  Front_Places = "/placowki",
  Front_PlacesParametrized = "/placowki/:category/:city",
  Front_Place = "/placowka/:id",

  Front_Events = "/wydarzenia",
  Front_EventsParametrized = "/wydarzenia/:category/:city",

  Place_Reservations = "/place-account/reservations",
  Place_PastReservations = "/place-account/past-reservations",
  MyAccount_Chats = "/my-account/chats",

  Download_Invoice = `/api/payments/invoice?id={{id}}`,
  Pricing = "/cennik",

  Specialist_SignUpPackage = "/specialist-sign-up/package",
  Specialist_SignUpVariant = "/specialist-sign-up/variant",
  Specialist_SignUp_PaymentSuccess = "/specialist-sign-up/success",
  Specialist_SignUp_PaymentFailed = "/specialist-sign-up/failed",

  Place_SignUp_PaymentSuccess = "/place-sign-up/success",
  Place_SignUp_PaymentFailed = "/place-sign-up/failed",
  Place_SignUp_Package = "/place-sign-up/package",
  Place_SignUp_ConfirmRequired = "/place-sign-up/confirm-required",

  Place_SignUp_Variant = "/place-sign-up/variant",
}

export const createRoute = (
	route: RoutesEnum,
	context: Record<string, any> = {}
) => createRouteTemplate<RoutesEnum>(route, context);
