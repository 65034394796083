import React from 'react';

type Props = {
  register: any
  name: string
  value: string
};

export const EntityRadio: React.FC<Props> = ({
  register,
  name,
  children,
  value
}) => {
  return <>
    <div>
      <input id={`${name}-${value}`} type="radio" {...register(name)} className="hidden peer" value={value} />
      <label htmlFor={`${name}-${value}`} className="text-white peer-checked:font-bold text-center">
        <span className="absolute">{children}</span>
        <span className="font-bold text-transparent">
          {children}
        </span>
      </label>
    </div>
  </>;
};
